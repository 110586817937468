// ProfileTable.js
import React, { useState } from "react";
import "../../custom-css/ProfileTable.css";
import "../../custom-css/App.css";
import { formatDate } from "../../functions/date_format";
import ModalSetPaidPackage from "./Modals/ModalSetPaidPackage";

const PackageDetailsTable = ({ packageDetails, updatePackage }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const paidLine = () => {
    if (!packageDetails?.paid) {
      return (
        <td className="table-cell">
          <div className="paid-line">
            <i class="fa-solid fa-xmark"></i>
            <button onClick={handleSetPaid} className="button-10">
              Set paid
            </button>
          </div>
        </td>
      );
    }

    return (
      <td className="table-cell">
        <i class="fa-solid fa-check"></i>
      </td>
    );
  };

  const handleSetPaid = () => {
    setIsModalOpen(true);
  };

  // Function to handle closing the modal
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="profile-table-container table-pack-details">
      <div className="title-table-notes">
        <h3>Package details</h3>
      </div>
      <table className="profile-table">
        <tbody>
          <tr>
            <td className="cell-header profile-table-row-title">ID:</td>
            <td className="table-cell">{packageDetails?.id}</td>
          </tr>
          <tr>
            <td className="cell-header profile-table-row-title">
              Package name:
            </td>
            <td className="table-cell">{packageDetails?.name}</td>
          </tr>
          <tr>
            <td className="cell-header profile-table-row-title">
              Client name:
            </td>
            <td className="table-cell">{packageDetails?.client_name}</td>
          </tr>
          <tr>
            <td className="cell-header profile-table-row-title">
              Consultations Number
            </td>
            <td className="table-cell">
              {packageDetails?.number_consultations}
            </td>
          </tr>
          <tr>
            <td className="cell-header profile-table-row-title">
              Consultations Remaining:
            </td>
            <td className="table-cell">
              {packageDetails?.number_consultations_remaining}
            </td>
          </tr>
          <tr>
            <td className="cell-header profile-table-row-title">Paid:</td>
            {paidLine()}
          </tr>
          <tr>
            <td className="cell-header profile-table-row-title">Price:</td>
            <td className="table-cell">{packageDetails?.price}€</td>
          </tr>
          <tr>
            <td className="cell-header profile-table-row-title">
              Method Payment:
            </td>
            <td className="table-cell">
              {packageDetails?.payment_method === "not selected"
                ? "-"
                : packageDetails?.payment_method}
            </td>
          </tr>
          <tr>
            <td className="cell-header profile-table-row-title">Created on:</td>
            <td className="table-cell">{formatDate(packageDetails?.date)}</td>
          </tr>
        </tbody>
      </table>
      <ModalSetPaidPackage
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onPaidSet={updatePackage}
        package_id={packageDetails?.id}
      />
    </div>
  );
};

export default PackageDetailsTable;
