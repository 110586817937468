// api.js
import { INSERT_CLIENTS_URL, UPDATE_CLIENT_PROFILE_URL } from "./api_urls";
import { getAdminId, getSessionId } from "./api_urls";

export const insertClient = async (clientData) => {
  const apiUrl = INSERT_CLIENTS_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
    },
    body: JSON.stringify(clientData),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    const statusCode = response.status;

    // Parse the response data if it's a successful status code
    if (response.ok) {
      const data = await response.json();

      return { success: true, data, statusCode };
    } else {
      const error = await response.json();

      // Handle non-successful status codes here
      return { success: false, error };
    }
  } catch (error) {
    throw new Error("An error occurred while inserting the client.");
  }
};

export const updateClientProfile = async (clientData, clientId) => {
  const apiUrl = UPDATE_CLIENT_PROFILE_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "client-id": clientId,
    },
    body: JSON.stringify(clientData),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    const statusCode = response.status;

    // Parse the response data if it's a successful status code
    if (response.ok) {
      const data = await response.json();

      return { success: true, data, statusCode };
    } else {
      const error = await response.json();

      // Handle non-successful status codes here
      return { success: false, error };
    }
  } catch (error) {
    throw new Error("An error occurred while inserting the client.");
  }
};
