// api/auth.js
import { AUTH_URL } from "./api_urls"

// Function to perform login API call
export const login = async (username, password) => {
  const apiUrl = AUTH_URL;
  const requestOptions = {
    method: "POST",
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username: username, password: password }), // Include both username and password
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    console.log(response);

    if (response.ok) {
      const data = await response.json();
      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};
