import React, { useState, useEffect } from "react";
import "../../custom-css/ConsultationDetails.css";
import ErrorModal from "../../GeneralModal/ErrorSessionModal";
import RichTextEditor from "../../GenericComponents/RichTextEditor";
import TitleTables from "../../GenericComponents/TitleTables";

const ConsultationForm = ({
  consultationDetails,
  getTitles,
  getResponse,
  insertResponse,
  title,
}) => {
  const [formData, setFormData] = useState([]);
  const [editorHtml, setEditorHtml] = useState({});
  const [questionOrder, setQuestionOrder] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTitles(consultationDetails.language_code);
        if (response.success) {
          const formQuestions = response.data;

          // Make a separate API call to get the saved rich text values
          const savedRichTextResponse = await getResponse(
            consultationDetails.client_id,
            consultationDetails.id
          );

          if (savedRichTextResponse.success) {
            // Create a map of question_id to response from the API response

            const initialEditorHtml = {};
            const order = [];
            if (savedRichTextResponse.data.length === 0) {
              formQuestions.forEach((item) => order.push(item.id));
              // const order = formQuestions.map((item) => item.id);
              setQuestionOrder(order);
            } else {
              savedRichTextResponse.data.forEach((item) => {
                initialEditorHtml[item.question_id] = item.response || "";
                order.push(item.question_id); // Push question_id to order
              });

              setEditorHtml(initialEditorHtml);
              setQuestionOrder(order); // Set the order
            }
          }

          setFormData(formQuestions);
        } else {
          console.error("Fetching form data failed:", response.error);
          setShowErrorModal(true);
        }
      } catch (error) {
        console.error("Error during form data fetching:", error);
        setShowErrorModal(true);
      }
    };

    fetchData();
  }, [consultationDetails.language_code]);

  const handleEditorChange = (html, questionId) => {
    setEditorHtml((prevEditorHtml) => ({
      ...prevEditorHtml,
      [questionId]: html,
    }));
  };

  const handleSaveClick = async () => {
    try {
      const arrayRes = [];

      questionOrder.forEach((questionId) => {
        const singleResponse = {
          question_id: questionId,
          textResponse: editorHtml[questionId] || "",
        };

        arrayRes.push(singleResponse);
      });

      const response = await insertResponse({
        client_id: consultationDetails.client_id,
        responses: arrayRes,
        consultation_id: consultationDetails.id,
      });

      if (response.success) {
        alert("Form Saved Correctly");
      } else {
        console.error("Failed to save rich text:", response.error);
      }
    } catch (error) {
      console.error("Error while saving rich text:", error);
    }
  };

  return (
    <div className="form-container questionnaire-container">
      <TitleTables
        title={title}
        buttonText={"Save"}
        onClick={handleSaveClick}
      />
      <table className="form-consultation-table">
        <tbody>
          <tr>
            <td>
              <form className="form-consultation">
                {formData.map((item, i) => (
                  <div className="form-group-consultation" key={item.id}>
                    <label htmlFor={`title${item.id}`}>
                      {i + 1} - {item.title}
                    </label>
                    <p className="form-consultation-subtitle">
                      {item.subtitle}
                    </p>
                    <RichTextEditor
                      value={editorHtml[item.id] || ""}
                      onChange={(html) => handleEditorChange(html, item.id)}
                    />
                  </div>
                ))}
              </form>
            </td>
          </tr>
        </tbody>
      </table>
      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};

export default ConsultationForm;
