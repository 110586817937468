// api/auth.js
import { GET_FORM_SECTIONA_URL } from "./api_urls"
import { GET_SECTIONA_RESPONSE_URL } from "./api_urls"
import { INSERT_SECTIONA_URL } from "./api_urls"

import { getAdminId, getSessionId } from "./api_urls";

// Function to perform login API call
export const getSectionA = async (id) => {
    const apiUrl = GET_FORM_SECTIONA_URL;
    const adminId = getAdminId();
    const sessionId = getSessionId();
    const requestOptions = {
        method: "GET",
        headers: { 'Content-Type': 'application/json', 'admin-id': adminId, 'session-id': sessionId, 'language-id': id },
    };

    try {
        const response = await fetch(apiUrl, requestOptions);


        if (response.ok) {
            const data = await response.json();

            return { success: true, data };
        } else {
            const errorData = await response.json();
            return { success: false, error: errorData };
        }
    } catch (error) {
        console.error("Error during login:", error);
        throw new Error("An error occurred while logging in.");
    }
};


export const getSectionAResponse = async (id, consultationId) => {
    const apiUrl = GET_SECTIONA_RESPONSE_URL;
    const adminId = getAdminId();
    const sessionId = getSessionId();
    const requestOptions = {
        method: "GET",
        headers: { 'Content-Type': 'application/json', 'admin-id': adminId, 'session-id': sessionId, 'client-id': id, 'consultation-id': consultationId },
    };

    try {
        const response = await fetch(apiUrl, requestOptions);


        if (response.ok) {
            const data = await response.json();

            return { success: true, data };
        } else {
            const errorData = await response.json();
            return { success: false, error: errorData };
        }
    } catch (error) {
        console.error("Error during login:", error);
        throw new Error("An error occurred while logging in.");
    }
};

export const insertSectionA = async (requestBody, consultationId) => {
    const apiUrl = INSERT_SECTIONA_URL; // Replace with your API endpoint URL
    const adminId = getAdminId(); // Implement or replace with the function to get the admin ID
    const sessionId = getSessionId(); // Implement or replace with the function to get the session ID
    const requestOptions = {
        method: "POST", // Use POST method to send data
        headers: { 'Content-Type': 'application/json', 'admin-id': adminId, 'session-id': sessionId, 'consultation-id': consultationId },
        body: JSON.stringify(requestBody),
    };

    try {
        const response = await fetch(apiUrl, requestOptions);

        if (response.ok) {
            const data = await response.json();
            return { success: true, data };
        } else {
            const errorData = await response.json();
            return { success: false, error: errorData };
        }
    } catch (error) {
        console.error("Error during insertSectionA:", error);
        throw new Error("An error occurred while inserting Section A data.");
    }
};
