import React, { useState } from "react";
import { Calendar } from "rsuite";
import "rsuite/Calendar/styles/index.css";
import "../custom-css/Custom-Calendar.css";
import { enUS } from "date-fns/locale"; // Import the English (US) locale

const MyCalendar = ({ onSelect, appointments }) => {
  const [date, setDate] = useState(new Date());

  const onChange = (newDate) => {
    setDate(newDate);
    // setSelectedDate(newDate); // Update the selected date when the calendar date changes
  };

  // Function to add an appointment for the selected date

  function renderCell(date) {
    // Check if the current date is included in the list of appointments
    const isAppointmentDate = appointments.some((appointment) => {
      const appointmentDate = new Date(appointment.date);
      const calendarDate = new Date(date);
      return (
        appointmentDate.getFullYear() === calendarDate.getFullYear() &&
        appointmentDate.getMonth() === calendarDate.getMonth() &&
        appointmentDate.getDate() === calendarDate.getDate()
      );
    });

    if (isAppointmentDate) {
      return <div className="appointment-circle" />;
    }

    return null;
  }
  // Function to handle day click event and show popover
  function handleDayClick(date) {
    // setSelectedDate(date);
    onSelect(date);
  }

  return (
    <div className="calendar-container">
      <div className="calendar">
        <Calendar
          isoWeek={true}
          onChange={onChange}
          value={date}
          locale={enUS}
          bordered={true}
          compact={true}
          cellClassName={(date) => (date.getDay() % 2 ? "bg-gray" : undefined)}
          renderCell={renderCell}
          onSelect={handleDayClick} // Attach onSelect event handler to handle day click
        />
      </div>
    </div>
  );
};

export default MyCalendar;
