import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../custom-css/UserTable.css";

const LeadsTable = ({ leadsDetails, showConverted }) => {
  const [searchQuery, setSearchQuery] = useState(""); // State variable for search query
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15; // Number of items to display per page

  // Filter the users based on the search query
  const filteredLeads = leadsDetails.filter(
    (user) =>
      user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.phone_number.includes(searchQuery.toLowerCase()) ||
      user.email_address.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredLeads.length / itemsPerPage);

  // Function to generate an array of visible page numbers
  const getVisiblePageNumbers = () => {
    const maxPageButtons = Math.ceil(leadsDetails.length / 10) + 1; // Number of page buttons to display

    if (totalPages <= maxPageButtons) {
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    } else {
      const halfButtons = Math.floor(maxPageButtons / 2);
      const firstVisiblePage = Math.max(1, currentPage - halfButtons);
      const lastVisiblePage = Math.min(totalPages, currentPage + halfButtons);

      return Array.from(
        { length: lastVisiblePage - firstVisiblePage + 1 },
        (_, index) => firstVisiblePage + index
      );
    }
  };

  // Handle changes in the search input
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when the search query changes
  };

  // Handle pagination button clicks
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      {/* Search bar */}
      <div className="search-user">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          placeholder="Search by name, phone number, email address"
          value={searchQuery}
          onChange={handleSearchInputChange}
          className="search-bar"
        />
      </div>
      <div>
        <table className="table-users">
          <thead>
            <tr>
              <th className="table-header">ID</th>
              <th className="table-header">Name</th>
              <th className="table-header">Email</th>
              <th className="table-header">Phone</th>
              {showConverted ? (
                <th className="table-header">Converted</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {filteredLeads
              .slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
              )
              .map((lead) => (
                <tr key={lead.id}>
                  <td className="table-cell">{lead.id}</td>
                  <td className="table-cell">
                    <Link
                      to={`/leads/details/${lead.id}`}
                      className="user-link"
                    >
                      {lead.name}
                    </Link>
                  </td>
                  <td className="table-cell">{lead.email_address}</td>
                  <td className="table-cell">{lead.phone_number}</td>
                  {showConverted ? (
                    <td className="table-cell">
                      <i class="fa-solid fa-circle-check"></i>
                    </td>
                  ) : null}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* Pagination */}
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn-pagination"
        >
          Previous
        </button>
        {getVisiblePageNumbers().map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={
              currentPage === page
                ? "btn-pagination btn-pagination-active"
                : "btn-pagination"
            }
          >
            {page}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="btn-pagination"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default LeadsTable;
