import React, { useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import { changeStatusConsultation } from "../../api/set_date_consultation";

const ModalChangeStatusConsultation = ({
  isOpen,
  onClose,
  onChangeStatusDone,
  consultation_id,
}) => {
  const [selectedStatus, setSelectedStatus] = useState(1);

  const handleChangeStatus = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleSave = async () => {
    try {
      const response = await changeStatusConsultation(
        consultation_id,
        selectedStatus
      ); // Fetch client data
      if (response.success) {
        onClose();
        if (onChangeStatusDone) {
          onChangeStatusDone();
        }
        alert(`Consultation status changed.`);
      } else {
        // Handle fetch failure
        onClose();
        alert("There has been an error. Please try again.");
        console.error("Fetching client data failed:", response.error);
      }
    } catch (error) {
      onClose();
      alert("There has been an error. Please try again.");
      console.error("Error during client data fetching:", error);
      // Handle error
    }
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal add-package-modal date-modal">
        <div className="modal-content">
          <h2>
            <i class="fa-solid fa-file-pen"></i>Choose status
          </h2>
          <label htmlFor="selectedStatus">Status</label>
          <div className="select-container">
            <select
              id="selectedStatus"
              name="new_status"
              value={selectedStatus}
              onChange={handleChangeStatus}
              className="add-package-select"
            >
              <option value="1">Completed</option>
              <option value="2">Cancelled</option>
              <option value="3">Pending</option>
              {/* Add more options as needed */}
            </select>
          </div>
          <div className="modal-buttons">
            <button onClick={onClose} className="button-12">
              Cancel
            </button>
            <button onClick={handleSave} className="button-10">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ModalChangeStatusConsultation;
