// utils/auth.js
export const getAdminId = () => localStorage.getItem("admin_id");
export const getSessionId = () => localStorage.getItem("session_id");
export const getLanguageId = () => localStorage.getItem("language_id");
export const getSessionClient = () => localStorage.getItem("session-id");

export const domain = "https://api.elenanutritionist.com/";
export const domainClientArea = "https://clients.elenanutritionist.com/";

export const CLIENT_AREA_LINK = `${domain}clients/login`;

export const AUTH_URL = `${domain}api/admin/login`;
export const GET_CLIENTS_URL = `${domain}api/v1/getClients`;
export const INSERT_CLIENTS_URL = `${domain}api/v1/insertClient`;
export const GET_CLIENT_DATA_URL = `${domain}api/v1/getClientData`;
export const GET_CLIENTS_LOGIN_URL = `${domain}api/v1/getClientsLogin`;
export const UPDATE_CLIENT_PROFILE_URL = `${domain}api/v1/updateProfileClient`;
export const GET_INTAKE_FORM_URL = `${domain}api/v1/getIntakeForm`;
export const GET_INTAKE_FORM_EN_URL = `${domain}api/v1/getIntakeEn`;
export const GET_INTAKE_FORM_RU_URL = `${domain}api/v1/getIntakeRu`;
export const GET_INTAKE_FORM_RESPONSE_URL = `${domain}api/v1/getIntakeResponse`;
export const INSERT_INTAKE_FORM_URL = `${domain}api/v1/insertIntakeForm`;
export const SET_INTAKE_FORM_COMPLETED_URL = `${domain}api/v1/setIntakeCompleted`;

export const GET_PACKAGES_CLIENT_URL = `${domain}api/v1/getPackagesClient`;
export const GET_PACKAGE_DETAILS_URL = `${domain}api/v1/getPackageDetails`;
export const INSERT_PACKAGE_URL = `${domain}api/v6/insertPackage`;
export const SET_PAID_PACKAGE_URL = `${domain}api/v6/setPaid`;

export const GET_CONSULTATIONS_URL = `${domain}api/v2/getConsultations`;
export const GET_CONSULTATIONS_CLIENT_URL = `${domain}api/v2/getConsultationsByClient`;
export const GET_CONSULTATIONS_PACKAGE_URL = `${domain}api/v2/getConsultationsByPackage`;
export const GET_CONSULTATION_ID_URL = `${domain}api/v2/getConsultationById`;
export const SET_DATE_CONSULTATION_URL = `${domain}api/v2/updateDateConsultation`;
export const SET_STATUS_CONSULTATION_URL = `${domain}api/v2/changeStatusConsultation`;

export const GET_LEADS_URL = `${domain}api/v3/getLeads`;
export const GET_LEAD_DETAILS_URL = `${domain}api/v3/getLeadDetails`;
export const INSERT_LEAD_URL = `${domain}api/v3/insertLead`;
export const UPDATE_LEAD_URL = `${domain}api/v3/updateLead`;
export const CONVERT_LEAD_URL = `${domain}api/v3/convertLead`;
export const GET_LEAD_NOTES_URL = `${domain}api/v3/getNotesByLead`;
export const INSERT_LEAD_NOTE_URL = `${domain}api/v3/insertLeadNote`;
export const DELETE_LEAD_NOTE_URL = `${domain}api/v3/deleteLeadNote`;

export const GET_FORM_SECTIONA_URL = `${domain}api/v2/getSectionA`;
export const GET_SECTIONA_RESPONSE_URL = `${domain}api/v2/getSectionAResponses`;
export const INSERT_SECTIONA_URL = `${domain}api/v2/insertSectionA`;

export const GET_FORM_SECTION_B_URL = `${domain}api/v2/getSectionB`;
export const GET_SECTION_B_RESPONSE_URL = `${domain}api/v2/getSectionBResponses`;
export const INSERT_SECTION_B_URL = `${domain}api/v2/insertSectionB`;

export const GET_FORM_SECTION_C_URL = `${domain}api/v2/getSectionC`;
export const GET_SECTION_C_RESPONSE_URL = `${domain}api/v2/getSectionCResponses`;
export const INSERT_SECTION_C_URL = `${domain}api/v2/insertSectionC`;

export const GET_FOLLOW_UP_FORM_URL = `${domain}api/v2/getFollowUpForm`;
export const GET_FOLLOW_UP_FORM_RESPONSE_URL = `${domain}api/v2/getFollowUpResponse`;
export const INSERT_FOLLOW_UP_FORM_URL = `${domain}api/v2/insertFollowUp`;

export const GET_RECOMMENDATION_URL = `${domain}api/v2/getRecommendation`;
export const GET_RECOMMENDATION_RESPONSES_URL = `${domain}api/v2/getRecommendationResponse`;
export const INSERT_RECOMMENDATION_URL = `${domain}api/v2/insertRecommendation`;

export const GET_ALL_APPOINTMENTS_URL = `${domain}api/v5/getAllAppointments`;
export const GET_APPOINTMENTS_BY_DATE_URL = `${domain}api/v5/getAppointmentsByDate`;

export const GET_ALL_DISCOVERY_CALLS_URL = `${domain}api/v3/getDiscoveryCalls`;
export const GET_DISCOVERY_CALL_BY_ID_URL = `${domain}api/v3/getDiscoveryByLead`;
export const SET_DATE_DISCOVERY_URL = `${domain}api/v3/updateDateDiscovery`;
export const DELETE_DISCOVERY_URL = `${domain}api/v3/deleteDiscovery`;

export const GET_ALL_NOTES_CLIENT_URL = `${domain}api/v4/getNotesByClient`;
export const INSERT_NOTE_CLIENT_URL = `${domain}api/v4/insertClientNote`;
export const DELETE_NOTE_CLIENT_URL = `${domain}api/v4/deleteClientNote`;

export const GET_ALL_SUPPLEMENTS_URL = `${domain}api/v2/getAllSupplements`;
export const GET_SUPPLEMENT_BY_ID = `${domain}api/v2/getSupplementById`;
export const GET_SUPPLEMENTS_BY_CONSULTATION_URL = `${domain}api/v2/getSupplement`;
export const GET_SUPP_SPEC_BY_CONSULTATION_URL = `${domain}api/v2/getSupplementSpec`;
export const INSERT_NEW_SUPPLEMENT_LIST_URL = `${domain}api/v2/insertNewSupplement`;
export const INSERT_SUPPLEMENT_CLIENT_URL = `${domain}api/v2/insertSupplement`;
export const INSERT_SUPP_SPEC_CLIENT_URL = `${domain}api/v2/insertSupplementSpec`;
export const DELETE_SUPPLEMENT_CLIENT_URL = `${domain}api/v2/deleteSupplement`;
export const DELETE_SUPPLEMENT_LIST_URL = `${domain}api/v2/deleteSupplementList`;
export const EDIT_SUPPLEMENT_URL = `${domain}api/v2/editSupplement`;
export const EDIT_SUPPLEMENT_CLIENT_URL = `${domain}api/v2/editSupplementClient`;
export const GET_SUPPLEMENTS_PREVIOUS_CONSULTATION = `${domain}api/v2/getSupplementsPrevious`;

export const TEST_URL = `${domain}api/pdf/pdfRec`;

export const CLIENTS_LOGIN_URL = `${domain}api/clients/v1/login`;
export const CONSULTATIONS_CLIENTS_LOGIN_URL = `${domain}api/clients/v1/consultations`;
export const RECOMMENDATIONS_CLIENTS_LOGIN_URL = `${domain}api/clients/v1/recommendations`;
export const SUPPLEMENTS_CLIENTS_LOGIN_URL = `${domain}api/clients/v1/supplements`;

export const GET_ALL_RECORDS_URL = `${domain}api/v7/getAllRecords`;
export const GET_RECORD_TYPE_URL = `${domain}api/v7/getRecordType`;
export const UPLOAD_RECORD_URL = `${domain}api/v7/uploadRecord`;
export const INSERT_RECORD_URL = `${domain}api/v7/insertRecord`;
export const GET_RECORD_BY_ID_URL = `${domain}api/v7/getRecordById`;
export const GET_RECORD_BY_CLIENT_URL = `${domain}api/v7/getRecordByClient`;
export const DELETE_RECORD_URL = `${domain}api/v7/deleteRecord`;
export const SET_FOLLOW_UP_URL = `${domain}api/v2/setFollowUp`;
