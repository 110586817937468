// api/auth.js
import { GET_CLIENTS_LOGIN_URL, GET_CLIENTS_URL } from "./api_urls"
import { getAdminId, getSessionId } from "./api_urls";

// Function to perform login API call
export const getClients = async () => {
  const apiUrl = GET_CLIENTS_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: { 'Content-Type': 'application/json', 'admin-id': adminId, 'session-id': sessionId },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);


    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const getClientLogin = async (clientId) => {
  const apiUrl = GET_CLIENTS_LOGIN_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: { 'Content-Type': 'application/json', 'admin-id': adminId, 'session-id': sessionId, 'client-id': clientId },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);


    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};