import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TopRow from "../../TopRow/TopRow";
import "../../custom-css/UserDetails.css";
import "../../custom-css/ConsultationDetails.css";
import ConsultationDetailsTable from "./ConsultationDetailsTable";
import ConsultationForm from "./ConsultationForm";
import { getConsultationById } from "../../api/get_consultation_id";
import {
  getSectionA,
  getSectionAResponse,
  insertSectionA,
} from "../../api/get_section_a";
import {
  getSectionB,
  getSectionBResponse,
  insertSectionB,
} from "../../api/form_section_b";
import {
  getSectionC,
  getSectionCResponse,
  insertSectionC,
} from "../../api/form_section_c";
import SelectableTextTable from "./ListItems";
import {
  getFollowUpForm,
  getFollowUpResponse,
  insertFollowUp,
} from "../../api/follow_up_form";
import ErrorModal from "../../GeneralModal/ErrorSessionModal";
import {
  getRecommendation,
  getRecommendationResponse,
  insertRecommendation,
} from "../../api/form_recommendation";
import SupplementForm from "./SupplementForm";

const ConsultationDetails = () => {
  const { id } = useParams();
  const [consultationDetails, setconsultationDetails] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectableTexts, setSelectableTexts] = useState([]);

  const [showErrorModal, setShowErrorModal] = useState(false);

  const fetchData = async () => {
    try {
      const response = await getConsultationById(id); // Fetch client data
      if (response.success) {
        // Assuming the fetched data is in response.data as an array of objects
        setconsultationDetails(response.data);

        if (response.data.order === 1) {
          const selectable = [
            { id: 1, title: "Section A" },
            { id: 2, title: "Section B" },
            { id: 3, title: "Section C" },
            { id: 4, title: "Recommendations" },
            { id: 7, title: "Supplements" },
          ];
          setSelectableTexts(selectable);
        } else {
          const selectable = [
            { id: 5, title: "Follow Up Form" },
            { id: 6, title: "Recommendations" },
            { id: 7, title: "Supplements" },
          ];
          setSelectableTexts(selectable);
        }
      } else {
        // Handle fetch failure
        console.error("Fetching client data failed:", response.error);
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error("Error during client data fetching:", error);
      setShowErrorModal(true);
      // Handle error
    }
  };

  useEffect(() => {
    fetchData(); // Call the fetchData function to fetch client data
  }, [id]);

  const handleSelect = (id) => {
    console.log("selectedid", id);
    setSelectedId(id);
  };

  const updateConsultation = () => {
    fetchData();
  };

  const renderComponentById = (selectedId) => {
    if (selectedId === null) {
      return null; // Render nothing if no section is selected
    }
    switch (selectedId) {
      case 1:
        return (
          <ConsultationForm
            key={selectedId}
            consultationDetails={consultationDetails}
            getTitles={getSectionA}
            getResponse={getSectionAResponse}
            insertResponse={insertSectionA}
            title={selectableTexts[selectedId - 1].title}
          />
        );
      case 2:
        return (
          <ConsultationForm
            key={selectedId}
            consultationDetails={consultationDetails}
            getTitles={getSectionB}
            getResponse={getSectionBResponse}
            insertResponse={insertSectionB}
            title={selectableTexts[selectedId - 1].title}
          />
        );
      case 3:
        return (
          <ConsultationForm
            key={selectedId}
            consultationDetails={consultationDetails}
            getTitles={getSectionC}
            getResponse={getSectionCResponse}
            insertResponse={insertSectionC}
            title={selectableTexts[selectedId - 1].title}
          />
        );

      case 4:
        return (
          <ConsultationForm
            key={selectedId}
            consultationDetails={consultationDetails}
            getTitles={getRecommendation}
            getResponse={getRecommendationResponse}
            insertResponse={insertRecommendation}
            title={selectableTexts[selectedId - 1].title}
          />
        );
      case 5:
        return (
          <ConsultationForm
            key={selectedId}
            consultationDetails={consultationDetails}
            getTitles={getFollowUpForm}
            getResponse={getFollowUpResponse}
            insertResponse={insertFollowUp}
            // title={selectableTexts[selectableTexts.length - 2].title}
            title={selectableTexts[0].title}
          />
        );
      case 6:
        return (
          <ConsultationForm
            key={selectedId}
            consultationDetails={consultationDetails}
            getTitles={getRecommendation}
            getResponse={getRecommendationResponse}
            insertResponse={insertRecommendation}
            title={selectableTexts[1].title}
          />
        );
      case 7:
        return <SupplementForm consultationDetails={consultationDetails} />;
      // Add more cases for other components
      default:
        return null; // Render nothing if no match
    }
  };

  return (
    <div className="user-details-main-container">
      <div className="navbar">
        <TopRow adminName={"Admin"} />
      </div>
      <div className="user-details-internal-container">
        <h2>
          Consultation /{" "}
          {consultationDetails && (
            <Link
              to={`/user/details/${consultationDetails.client_id}`}
              className="user-link"
              target="_blank"
            >
              {consultationDetails.client_name}
            </Link>
          )}{" "}
          / {id}
        </h2>
        {/* <div className="user-details-first-row"> */}
        <div className="consultation-details-row">
          <ConsultationDetailsTable
            consultationDetails={consultationDetails}
            updateConsultation={updateConsultation}
          />
          <SelectableTextTable
            texts={selectableTexts}
            onSelect={handleSelect}
          />
        </div>
        {selectedId && renderComponentById(selectedId)}
      </div>
      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};

export default ConsultationDetails;
