import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  changeFollowUpConsultation,
  setDateConsultation,
} from "../../api/set_date_consultation";
import { formatDateTime } from "../../functions/date_format";

const ModalSetFollowUp = ({
  isOpen,
  onClose,
  onChangeStatusDone,
  consultationDetails,
}) => {
  const handleSave = async () => {
    try {
      const response = await changeFollowUpConsultation(
        consultationDetails.id,
        consultationDetails.order
      ); // Fetch client data
      if (response.success) {
        onClose();
        if (onChangeStatusDone) {
          onChangeStatusDone();
        }
        alert(`Consultation has been modified!`);
      } else {
        // Handle fetch failure
        onClose();
        alert("There has been an error. Please try again.");
        console.error("Fetching client data failed:", response.error);
      }
    } catch (error) {
      onClose();
      alert("There has been an error. Please try again.");
      console.error("Error during client data fetching:", error);
      // Handle error
    }
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal add-package-modal date-modal">
        <div className="modal-content">
          <h2>
            <i class="fa-solid fa-pen-to-square"></i>Change Type Consultation
          </h2>
          <p>
            The actual type of consultation is:{" "}
            <strong>
              {consultationDetails.order === 1
                ? "First Consultation"
                : "Follow up"}
            </strong>
          </p>
          <p style={{ "margin-top": "10px" }}>
            Do you want to change to{" "}
            {consultationDetails.order !== 1
              ? "First Consultation"
              : "Follow up"}
            ?
          </p>

          <div className="modal-buttons">
            <button onClick={onClose} className="button-12">
              Cancel
            </button>
            <button onClick={handleSave} className="button-10">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ModalSetFollowUp;
