import React, { useEffect, useState } from "react";
import "../custom-css/Login.css";
import { consultationsClient } from "../api/clients_api";
import { getSessionClient } from "../api/api_urls";
import "../custom-css/ClientMainPage.css";
import { formatDateTime } from "../functions/date_format";
import { useNavigate } from "react-router-dom";

const ClientMainPage = () => {
  const [consultations, setConsultations] = useState([]);

  const sessionId = getSessionClient();
  const navigate = useNavigate();

  const getConsultations = async () => {
    try {
      const response = await consultationsClient(sessionId);

      if (response.success) {
        setConsultations(response.data);
      } else {
        alert("There has been an error");
      }
    } catch (error) {
      console.error("ClientMainPage error:", error.message);
      alert("An error occurred while logging in.");
    }
  };
  useEffect(() => {
    getConsultations(); // Call the fetchData function to fetch client data
  }, []);

  const handleRecommendationsClick = (consultationId) => {
    navigate(`/recommendation/${consultationId}/${sessionId}`);
  };

  return (
    <div className="client-mainpage-container">
      <h2>Welcome!</h2>
      {/* <div className="login-container">
        <h2></h2>
        <div className="row-btn-login"></div>
      </div> */}
      <h4>Your consultations</h4>
      {consultations.length === 0 ? (
        <p>No Consultations yet</p>
      ) : (
        consultations.map((item, i) => (
          <div className="consultation-item-client-container">
            <div>
              <h4>Consultation #{i + 1}</h4>
              <p className="sub-label">Date</p>
              <p>{formatDateTime(item.date)} </p>
            </div>
            <div className="consultation-btn-container">
              <button
                onClick={() => handleRecommendationsClick(item.id)}
                className="button-10"
              >
                Recommendations
              </button>
              {/* <button className="button-10">Supplements</button> */}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ClientMainPage;
