import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import "../../custom-css/ProfileTable.css";
import { formatDate } from "../../functions/date_format";
import { getPackagesClient } from "../../api/get_packages_client";
import AddPackageModal from "../Package/Modals/AddPackageModal";
import TitleTablesExpandable from "./TitleTablesExpandable";
import ErrorModal from "../../GeneralModal/ErrorSessionModal";

const PackagesTable = ({ updateConsultationsTable }) => {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [packageDetails, setpackageDetails] = useState([]);
  const [expand, setExpand] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const fetchData = async () => {
    try {
      const response = await getPackagesClient(id); // Fetch client data
      if (response.success) {
        // Assuming the fetched data is in response.data as an array of objects
        setpackageDetails(response.data);
      } else {
        // Handle fetch failure
        console.error("Fetching client data failed:", response.error);
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error("Error during client data fetching:", error);
      setShowErrorModal(true);
      // Handle error
    }
  };

  useEffect(() => {
    fetchData(); // Call the fetchData function to fetch client data
  }, [id]);

  const handleAddPackage = () => {
    // Open the modal when the "Add New Client" button is clicked
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    // Close the modal
    setIsModalOpen(false);
  };

  const handlePackageAdded = () => {
    fetchData();
    updateConsultationsTable();
  };

  const handleExpand = () => {
    setExpand(!expand);
  };

  return (
    <div className="profile-table-container">
      <TitleTablesExpandable
        title={"Packages"}
        buttonText={"Add new packages"}
        onClick={handleAddPackage}
        isExpanded={expand}
        expandTable={handleExpand}
      />
      {expand && packageDetails.length === 0 ? (
        <p>No packages yet</p>
      ) : expand ? (
        <table className="profile-table">
          <tbody>
            <tr>
              <td className="notes-table-column-title">ID</td>
              <td className="notes-table-column-title">Name</td>
              <td className="notes-table-column-title">Consultations</td>
              <td className="notes-table-column-title">Created on</td>
            </tr>
            {packageDetails.map((item) => (
              <tr key={item.id}>
                <td className="table-cell">
                  <Link to={`/user/package/${item.id}`} className="user-link">
                    {item.id}
                  </Link>
                </td>
                <td className="table-cell">{item.name}</td>
                <td className="table-cell">{item.number_consultations}</td>
                <td className="table-cell">{formatDate(item.date)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}

      <AddPackageModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onPackageAdded={handlePackageAdded}
        client_id={id}
      />
      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};

export default PackagesTable;
