// SuccessModal.js
import React from "react";
import "../custom-css/SuccessModal.css";

const SuccessModal = ({ isOpen, onClose, title }) => {
  return isOpen ? (
    <div className="success-modal-overlay">
      <div className="success-modal">
        <div className="success-modal-content">
          <h2>{title}</h2>
          <div className="modal-buttons">
            <button onClick={onClose}>OK</button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default SuccessModal;
