import React from "react";

import { formatDateTime } from "../../functions/date_format";
import { deleteDiscoveryCall } from "../../api/discovery_calls";

const DeleteDiscoveryModal = ({ isOpen, onClose, onDeleteDone, lead_id }) => {
  const handleDelete = async () => {
    try {
      const response = await deleteDiscoveryCall(lead_id); // Fetch client data
      if (response.success) {
        alert(`Discovery call deleted.`);
        onClose();
        if (onDeleteDone) {
          onDeleteDone();
        }
      } else {
        // Handle fetch failure
        onClose();
        alert("There has been an error. Please try again.");
        console.error("Fetching client data failed:", response.error);
      }
    } catch (error) {
      onClose();
      alert("There has been an error. Please try again.");
      console.error("Error during client data fetching:", error);
      // Handle error
    }
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal add-package-modal date-modal">
        <div className="modal-content">
          <h2>
            <i class="fa-solid fa-trash-can"></i>Delete discovery
          </h2>
          <p>Are you sure you want to delete this Discovery Call?</p>

          <div className="modal-buttons">
            <button onClick={onClose} className="button-12">
              Cancel
            </button>
            <button onClick={handleDelete} className="button-10">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default DeleteDiscoveryModal;
