// 
import React from "react";
import "../../custom-css/App.css";
import "../../custom-css/Tables.css";

const TitleTablesExpandable = ({ title, buttonText, onClick, isExpanded, expandTable }) => {
  return (
    <div className="title-container-expandable">
      <div className="title-table-notes-expanded">
        <h3>{title}</h3>
        {isExpanded && (
          <div className="btn-title-container">
            <button onClick={onClick} className="button-10 add-client-button">
              {buttonText}
            </button>
          </div>
        )}
      </div>
      <button onClick={expandTable} className="button-13">
        <p>{isExpanded ? "See less" : "See more"}</p>
        <i className={`fa-solid fa-chevron-${isExpanded ? "up" : "down"}`}></i>
      </button>
    </div>
  );
};

export default TitleTablesExpandable;
