// api/auth.js
import { GET_CONSULTATIONS_PACKAGE_URL } from "./api_urls";
import { getAdminId, getSessionId } from "./api_urls";

// Function to perform login API call
export const getConsultationsByPackage = async (id) => {
    const apiUrl = GET_CONSULTATIONS_PACKAGE_URL;
    const adminId = getAdminId();
    const sessionId = getSessionId();


    const requestOptions = {
        method: "GET",
        headers: { 'Content-Type': 'application/json', 'admin-id': adminId, 'session-id': sessionId, 'package-id': id },
    };

    try {
        const response = await fetch(apiUrl, requestOptions);

        if (response.ok) {
            const data = await response.json();
            return { success: true, data };
        } else {
            const errorData = await response.json();
            return { success: false, error: errorData };
        }
    } catch (error) {
        console.error("Error during login:", error);
        throw new Error("An error occurred while logging in.");
    }
};