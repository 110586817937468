// api/auth.js
import {
  GET_FORM_SECTIONA_URL,
  GET_INTAKE_FORM_EN_URL,
  GET_INTAKE_FORM_RESPONSE_URL,
  GET_INTAKE_FORM_RU_URL,
  INSERT_INTAKE_FORM_URL,
  SET_INTAKE_FORM_COMPLETED_URL,
} from "./api_urls";
import { GET_SECTIONA_RESPONSE_URL } from "./api_urls";
import { INSERT_SECTIONA_URL } from "./api_urls";

import { getAdminId, getSessionId } from "./api_urls";

// Function to perform login API call
export const getIntakeRu = async () => {
  const apiUrl = GET_INTAKE_FORM_RU_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const getIntakeEn = async () => {
  const apiUrl = GET_INTAKE_FORM_EN_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const getIntakeResponse = async (clientId) => {
  const apiUrl = GET_INTAKE_FORM_RESPONSE_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "client-id": clientId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const insertIntakeForm = async (requestBody, clientId) => {
  const apiUrl = INSERT_INTAKE_FORM_URL; // Replace with your API endpoint URL
  const adminId = getAdminId(); // Implement or replace with the function to get the admin ID
  const sessionId = getSessionId(); // Implement or replace with the function to get the session ID
  const requestOptions = {
    method: "POST", // Use POST method to send data
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
    },
    body: JSON.stringify(requestBody),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();
      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during Insert:", error);
    throw new Error("An error occurred while inserting Section A data.");
  }
};

export const setIntakeCompleted = async (clientId) => {
  const apiUrl = SET_INTAKE_FORM_COMPLETED_URL; // Replace with your API endpoint URL
  const adminId = getAdminId(); // Implement or replace with the function to get the admin ID
  const sessionId = getSessionId(); // Implement or replace with the function to get the session ID
  const requestOptions = {
    method: "POST", // Use POST method to send data
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "client-id": clientId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();
      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during Insert:", error);
    throw new Error("An error occurred while inserting Section A data.");
  }
};
