import React, { useState } from "react";
import { Link } from "react-router-dom";
import usersData from "../json/users.json";
import "../custom-css/UserTable.css";
import { formatDate } from "../functions/date_format";
import ModalDeleteRecord from "./Modal/ModalDeleteRecord";
const RecordsTable = ({ records, onRecordDeleted }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); // State variable for current page
  const itemsPerPage = 15; // Number of items to display per page

  // Calculate the total number of pages
  const totalPages = Math.ceil(records.length / itemsPerPage);

  const handleDelete = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function to generate an array of visible page numbers
  const getVisiblePageNumbers = () => {
    const maxPageButtons = Math.ceil(records.length / 10) + 1;

    if (totalPages <= maxPageButtons) {
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    } else {
      const halfButtons = Math.floor(maxPageButtons / 2);
      const firstVisiblePage = Math.max(1, currentPage - halfButtons);
      const lastVisiblePage = Math.min(totalPages, currentPage + halfButtons);

      return Array.from(
        { length: lastVisiblePage - firstVisiblePage + 1 },
        (_, index) => firstVisiblePage + index
      );
    }
  };

  // Serch name

  // Handle pagination button clicks
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <table className="table-users">
        <thead>
          <tr>
            <th className="table-header">ID</th>
            <th className="table-header">Client</th>
            <th className="table-header">Date</th>
            <th className="table-header">Type</th>
            <th className="table-header"></th>
          </tr>
        </thead>
        <tbody>
          {records
            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
            .map((record) => (
              <tr key={record.id}>
                <td className="table-cell">
                  <Link
                    to={`/record/details/${record.id}`}
                    className="user-link"
                  >
                    {record.id}
                  </Link>
                </td>
                <td className="table-cell">{record.client_name}</td>
                <td className="table-cell">{formatDate(record.date)}</td>
                <td className="table-cell">{record.type}</td>
                <td className="table-cell">
                  <button
                    onClick={() => {
                      setDeleteRecord(record.id);
                      handleDelete();
                    }}
                    className="button-12"
                  >
                    <i class="fa-solid fa-trash-can"></i>
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn-pagination"
        >
          Previous
        </button>
        {getVisiblePageNumbers().map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={
              currentPage === page
                ? "btn-pagination btn-pagination-active"
                : "btn-pagination"
            }
          >
            {page}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="btn-pagination"
        >
          Next
        </button>
      </div>
      <ModalDeleteRecord
        isOpen={isModalOpen}
        onClose={closeModal}
        recordId={deleteRecord}
        onRecordDeleted={onRecordDeleted}
      />
    </div>
  );
};

export default RecordsTable;
