import React from "react";
import "../../custom-css/ConsultationDetails.css";



// Create a new component for the selectable text table
const SelectableTextTable = ({ onSelect, texts }) => {
    return (
        <div className="list-document-container">
            <div className="title-table-notes">
                <h3>Select document</h3>
            </div>
            <table className="documents-available-table">
                <tbody>
                    {texts.map((textItem) => (
                        <tr key={textItem.id} onClick={() => onSelect(textItem.id)}>
                            <td className="list-document-title"><i class="fa-solid fa-file"></i>{textItem.title}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SelectableTextTable;