import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./custom-css/Sidebar.css";

const Sidebar = () => {
  const location = useLocation();
  const isUserDetailsPath = location.pathname.startsWith("/user/details");
  const isConsultationDetailsPath = location.pathname.startsWith(
    "/consultation/details"
  );
  const isRecordsDetailsPath = location.pathname.startsWith("/record/details");
  const isPackageDetailsPath = location.pathname.startsWith("/user/package");
  const isLeadDetailsPath = location.pathname.startsWith("/leads/details");
  const isSupplementDetailsPath = location.pathname.startsWith(
    "/supplements/details"
  );

  return (
    <div className="sidebar">
      <img
        className="img-logo-sidebar"
        src="https://api.elenanutritionist.com/uploads/img/favicon.png"
      ></img>
      <div className="logo">CRM</div>
      <ul className="menu">
        <li>
          <NavLink
            to="/dashboard"
            className={location.pathname === "/dashboard" ? "active-link" : ""}
          >
            <i className="fas fa-home icons-sidebar"></i>Dashboard
          </NavLink>
        </li>
        <div className="sidebar-title-section">Clients</div>
        <li>
          <NavLink
            to="/users"
            className={location.pathname === "/users" ? "active-link" : ""}
          >
            <i class="fa-solid fa-users icons-sidebar"></i>Clients
          </NavLink>
        </li>

        {isUserDetailsPath && (
          <ul className="submenu">
            <li>
              <NavLink
                className={
                  location.pathname.startsWith("/user/details")
                    ? "active-link"
                    : ""
                }
              >
                <i class="fa-regular fa-address-card icons-sidebar"></i>Client
                Details
              </NavLink>
            </li>
          </ul>
        )}
        {isPackageDetailsPath && (
          <ul className="submenu">
            <li>
              <NavLink
                className={
                  location.pathname.startsWith("/user/package")
                    ? "active-link"
                    : ""
                }
              >
                <i class="fa-solid fa-table icons-sidebar"></i>Package Details
              </NavLink>
            </li>
          </ul>
        )}
        <li>
          <NavLink
            to="/consultations"
            className={
              location.pathname === "/consultations" ? "active-link" : ""
            }
          >
            <i class="fa-solid fa-briefcase icons-sidebar"></i>Consultations
          </NavLink>
        </li>
        {isConsultationDetailsPath && (
          <ul className="submenu">
            <li>
              <NavLink
                className={
                  location.pathname.startsWith("/consultation/details")
                    ? "active-link"
                    : ""
                }
              >
                <i class="fa-solid fa-table icons-sidebar"></i>Consultation
                Details
              </NavLink>
            </li>
          </ul>
        )}
        <li>
          <NavLink
            to="/records"
            className={location.pathname === "/records" ? "active-link" : ""}
          >
            <i class="fa-solid fa-notes-medical icons-sidebar"></i>Records
          </NavLink>
        </li>
        {isRecordsDetailsPath && (
          <ul className="submenu">
            <li>
              <NavLink
                className={
                  location.pathname.startsWith("/record/details")
                    ? "active-link"
                    : ""
                }
              >
                <i class="fa-solid fa-table icons-sidebar"></i>Record Details
              </NavLink>
            </li>
          </ul>
        )}
        <div className="sidebar-title-section">Leads</div>
        <li>
          <NavLink
            to="/leads"
            className={location.pathname === "/leads" ? "active-link" : ""}
          >
            <i class="fa-solid fa-user icons-sidebar"></i>Leads
          </NavLink>
        </li>
        {isLeadDetailsPath && (
          <ul className="submenu">
            <li>
              <NavLink
                className={
                  location.pathname.startsWith("/leads/details")
                    ? "active-link"
                    : ""
                }
              >
                <i class="fa-solid fa-user icons-sidebar"></i>Leads Details
              </NavLink>
            </li>
          </ul>
        )}
        <li>
          <NavLink
            to="/discovery-calls"
            className={
              location.pathname === "/discovery-calls" ? "active-link" : ""
            }
          >
            <i class="fa-solid fa-phone icons-sidebar"></i>Discovery Calls
          </NavLink>
        </li>
        <div className="sidebar-title-section">Other</div>
        <li>
          <NavLink
            to="/supplements"
            className={
              location.pathname === "/supplements" ? "active-link" : ""
            }
          >
            <i class="fa-solid fa-capsules icons-sidebar"></i>Supplements
          </NavLink>
        </li>
        {isSupplementDetailsPath && (
          <ul className="submenu">
            <li>
              <NavLink
                className={
                  location.pathname.startsWith("/supplements/details")
                    ? "active-link"
                    : ""
                }
              >
                <i class="fa-solid fa-user icons-sidebar"></i>Supplement Details
              </NavLink>
            </li>
          </ul>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
