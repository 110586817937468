import React, { useState, useEffect } from "react";
import "../../custom-css/ConsultationDetails.css";
import AddSupplementModal from "./ModalAddSupplement";
import ErrorModal from "../../GeneralModal/ErrorSessionModal";
import {
  deleteSupplementClient,
  getSuppSpecByConsultation,
  getSupplementsByConsultation,
  insertSuppSpec,
} from "../../api/supplements";
import ModalEditSupplementClient from "./Modal/ModalEditSupplementClient";
import ModalPreviousSupplements from "./Modal/ModalPreviousSupplements";

const SupplementForm = ({ consultationDetails }) => {
  const [weeks, setWeek] = useState("");
  const [reviewWeeks, setReviewWeeks] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [supplementsClient, setSupplementsClient] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [suppSpec, setSuppSpec] = useState("");
  const [suppEditSelected, setSuppEditSelected] = useState(0);
  const [isOpenModalPrevious, setIsOpenModalPrevious] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const responseSupplements = await getSupplementsByConsultation(
        consultationDetails.id
      );
      const responseSuppSpec = await getSuppSpecByConsultation(
        consultationDetails.id
      );

      if (responseSupplements.success && responseSuppSpec.success) {
        setSupplementsClient(responseSupplements.data);
        setSuppSpec(responseSuppSpec.data);
        setWeek(responseSuppSpec.data.weeks);
        setReviewWeeks(responseSuppSpec.data.review_weeks);
      } else {
        console.error("Fetching clients failed:", responseSupplements.error);
        console.error("Fetching clients failed:", responseSuppSpec.error);
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error("Error during client data fetching:", error);
      setShowErrorModal(true);
    }
  };

  const saveSuppSpec = async () => {
    if (weeks === undefined || reviewWeeks === undefined) {
      alert("You need to insert both data!");
      return;
    }
    try {
      const response = await insertSuppSpec(
        consultationDetails.client_id,
        consultationDetails.id,
        weeks,
        reviewWeeks
      );
      if (response.success) {
        // Assuming the fetched data is in response.data
        alert("Supplement spec saved correctly");

        setWeek(response.data.weeks);
        setReviewWeeks(response.data.review_weeks);
      } else {
        // Handle fetch failureshowErrorModal("An error occurred while fetching data.");

        console.error("Fetching clients failed:", response.error);
        alert("Generic error");
      }
    } catch (error) {
      console.error("Error during client data fetching:", error);
      setShowErrorModal(true);
      // Handle error
    }
  };

  const handleAddSupplement = () => {
    // Open the modal when the "Add New Client" button is clicked
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    // Close the modal
    setIsModalOpen(false);
  };

  const handleModalPrevious = () => {
    // Open the modal when the "Add New Client" button is clicked
    setIsOpenModalPrevious(true);
  };

  const handleModalPreviousClose = () => {
    // Close the modal
    setIsOpenModalPrevious(false);
  };

  const handleEditSupplement = (supplement) => {
    setSuppEditSelected(supplement);
    setIsModalOpenEdit(true);
  };

  const handleEditClose = () => {
    setIsModalOpenEdit(false);
  };

  const handleSupplementAdded = () => {
    fetchData();
  };

  const handleDeleteSupp = async (id) => {
    try {
      const response = await deleteSupplementClient(id);
      if (response.success) {
        // Assuming the fetched data is in response.data
        alert("Supplement deleted correctly");
        fetchData();
      } else {
        // Handle fetch failureshowErrorModal("An error occurred while fetching data.");

        console.error("Fetching clients failed:", response.error);
        alert("Generic error");
      }
    } catch (error) {
      console.error("Error during client data fetching:", error);
      setShowErrorModal(true);
      // Handle error
    }
  };

  return (
    <div className="form-container questionnaire-container">
      <div className="title-table-notes">
        <h3>Supplements</h3>
        <div className="btn-title-container">
          <button
            onClick={handleAddSupplement}
            className="button-10 btn-add-supplement"
          >
            <i class="fa-solid fa-plus"></i>Add supplement
          </button>
          <button className="button-10 btn-previous"
            onClick={handleModalPrevious}
          >
            Supplements previous consultation
          </button>
        </div>
      </div>
      <div className="supplement-spec-div">
        <div className="btn-title-container save-supp-spec-btn">
          <button
            onClick={saveSuppSpec}
            className="button-10 add-client-button"
          >
            Save
          </button>
        </div>
        <div className="supplement-line-div">
          <div className="supplement-line-div-label">
            Number of weeks to follow:
          </div>
          <input
            type="number"
            name="weeks"
            placeholder="Number weeks"
            value={weeks}
            onChange={(e) => setWeek(e.target.value)}
          />
        </div>
        <div className="supplement-line-div">
          <label className="supplement-line-div-label">Review in weeks:</label>
          <input
            type="number"
            name="weeks"
            placeholder="Review in weeks"
            value={reviewWeeks}
            onChange={(e) => setReviewWeeks(e.target.value)}
          />
        </div>
      </div>
      {supplementsClient
        ? supplementsClient.map((supplement) => (
          <div className="supplement-spec-div supplement-details-container">
            <div className="img-container-supp">
              <img
                src={supplement.image_url}
                className="supplement-image"
              ></img>
            </div>
            <div className="supplement-title">
              <h4>{supplement.name}</h4>
              <a href={supplement.link} target="_blank">
                Link to shop
              </a>
              <div className="supp-details-container">
                <div className="supp-detail-line">
                  Dose: <span>{supplement.dose}</span>
                </div>
                <div className="supp-detail-line">
                  Morning: <span> {supplement.morning}</span>
                </div>
                <div className="supp-detail-line">
                  Day: <span>{supplement.day}</span>
                </div>
                <div className="supp-detail-line">
                  Evening: <span>{supplement.evening}</span>
                </div>
                <div className="supp-detail-line">
                  Notes: <span> {supplement.notes_instructions}</span>
                </div>
              </div>
            </div>
            <div>
              <button
                onClick={() => handleEditSupplement(supplement)}
                className="button-10 btn-edit-supp"
              >
                Edit
              </button>
              <button
                onClick={() => handleDeleteSupp(supplement.id)}
                className="button-12"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
          </div>
        ))
        : null}

      <AddSupplementModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSupplementAdded={handleSupplementAdded}
        consultationsDetails={consultationDetails}
      />
      <ModalPreviousSupplements
        isOpenModalPrevious={isOpenModalPrevious}
        onCloseModalPrevious={handleModalPreviousClose}
        onPreviousAdded={handleSupplementAdded}
        clientId={consultationDetails.client_id}
        consultationId={consultationDetails.id}
      />
      <ModalEditSupplementClient
        isOpen={isModalOpenEdit}
        onClose={handleEditClose}
        onEditDone={fetchData}
        initialFormData={suppEditSelected}
        supplementId={suppEditSelected.id}
        consultationId={consultationDetails.id}
      />

      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};

export default SupplementForm;
