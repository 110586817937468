// AddClientModal.js
import React, { useEffect, useState } from "react";

import "../../custom-css/AddClientModal.css";
import "../../custom-css/App.css";
import {
  getAllSupplements,
  insertSupplementClient,
} from "../../api/supplements";
import ErrorModal from "../../GeneralModal/ErrorSessionModal";
import AddNewSupplementList from "./Modal/ModalNewSupplementList";

const AddSupplementModal = ({
  isOpen,
  onClose,
  onSupplementAdded,
  consultationsDetails,
}) => {
  const [formData, setFormData] = useState({
    dose: "",
    morning: null,
    day: "",
    evening: "",
    notes: "",
  });
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [supplements, setSupplements] = useState([]);
  const [filteredSupplements, setFilteredSupplements] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSupplement, setSelectedSupplement] = useState("");

  const addNewSupplement = () => {
    // Open the modal when the "Add New Client" button is clicked
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    // Close the modal
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      setSearchQuery("");
      getSupplements();
      setFormData("");
      setSelectedSupplement("");
    }
  }, [isOpen]);

  const getSupplements = async () => {
    try {
      const response = await getAllSupplements();
      if (response.success) {
        // Assuming the fetched data is in response.data
        setSupplements(response.data);
      } else {
        // Handle fetch failureshowErrorModal("An error occurred while fetching data.");

        console.error("Fetching clients failed:", response.error);
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error("Error during client data fetching:", error);
      setShowErrorModal(true);
      // Handle error
    }
  };

  const handleSaveSupplement = async () => {
    try {
      const response = await insertSupplementClient(
        consultationsDetails.client_id,
        consultationsDetails.id,
        formData.dose,
        formData.morning,
        formData.day,
        formData.evening,
        formData.notes,
        selectedSupplement.id
      );
      if (response.success) {
        // Assuming the fetched data is in response.data
        setSupplements(response.data);
        onClose();
        if (onSupplementAdded) {
          onSupplementAdded();
        }
      } else {
        // Handle fetch failureshowErrorModal("An error occurred while fetching data.");

        console.error("Fetching clients failed:", response.error);
        alert("Generic error");
      }
    } catch (error) {
      console.error("Error during client data fetching:", error);
      setShowErrorModal(true);
      // Handle error
    }
  };

  const updateList = async () => {
    setSearchQuery("");
    getSupplements();
  };

  const handleSelecSupp = (supplement) => {
    setSelectedSupplement(supplement);
    setName(supplement.name);
    setLink(supplement.link);
    // handleChange();
  };

  const handleChange = (e) => {
    const { value } = e.target;

    setSearchQuery(value);

    if (supplements.length > 0) {
      // Filter supplements based on search query
      const filtered = supplements.filter((supplement) =>
        supplement.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSupplements(filtered);
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal add-client-modal supplement-modal">
        <div className="modal-content">
          <div className="title-add-supplement">
            <h2>
              <i class="fa-solid fa-pills"></i>Add supplement
            </h2>
          </div>
          {selectedSupplement ? null : (
            <div>
              <div className="search-supplement">
                <input
                  type="text"
                  placeholder="Type the supplement name"
                  value={searchQuery}
                  onChange={handleChange}
                  className="search-bar"
                />
                {/* <i class="fa-solid fa-circle-plus"></i> */}
              </div>
              {searchQuery && (
                <div className="supplement-list-result">
                  <div>
                    <h5>Available supplements</h5>
                  </div>
                  {filteredSupplements.length === 0 ? (
                    <button onClick={addNewSupplement} className="button-10">
                      Insert new supplement
                    </button>
                  ) : (
                    filteredSupplements.map((supplement) => (
                      <div
                        onClick={() => handleSelecSupp(supplement)}
                        key={supplement.id}
                        className="supplement-element-list"
                      >
                        <i class="fa-solid fa-caret-right"></i>
                        {supplement.name}
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
          )}
          {selectedSupplement ? (
            <div>
              <input
                type="text"
                name="name"
                placeholder="Name Supplement"
                value={name}
                // onChange={handleFormChange}
              />

              <input
                type="text"
                name="link"
                placeholder="Link Supplement"
                value={link}
                // onChange={handleFormChange}
              />

              <input
                type="text"
                onChange={handleFormChange}
                placeholder="Dose"
                name="dose"
                value={formData.dose}
              />

              <input
                type="text"
                name="morning"
                placeholder="Morning"
                value={formData.morning}
                onChange={handleFormChange}
              />

              <input
                type="text"
                name="day"
                placeholder="Day"
                value={formData.day}
                onChange={handleFormChange}
              />

              <input
                type="text"
                name="evening"
                placeholder="Evening"
                value={formData.evening}
                onChange={handleFormChange}
              />

              <input
                type="text"
                name="notes"
                placeholder="Notes/Instructions"
                value={formData.notes}
                onChange={handleFormChange}
              />
            </div>
          ) : null}
          <div className="modal-buttons">
            <button onClick={onClose} className="button-12">
              Cancel
            </button>
            <button onClick={handleSaveSupplement} className="button-10">
              Add
            </button>
          </div>
        </div>
      </div>
      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
      <AddNewSupplementList
        isOpen2={isModalOpen}
        onClose2={handleModalClose}
        onNewSuppAdded={updateList}
      />
    </div>
  ) : null;
};

export default AddSupplementModal;
