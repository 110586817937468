// AddClientModal.js
import React, { useEffect, useState } from "react";

import "../../../custom-css/AddClientModal.css";
import "../../../custom-css/App.css";
import "../../../custom-css/ModalNewSupplementList.css";
import { insertNewSupplement } from "../../../api/supplements";

const AddNewSupplementList = ({ isOpen2, onClose2, onNewSuppAdded }) => {
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    if (isOpen2) {
      // Reset input fields when modal is opened
      setName("");
      setLink("");
      setImage("");
    }
  }, [isOpen2]);

  const handleInsertSupplement = async () => {
    try {
      if (name.trim() === "" || link.trim() === "") {
        alert("Supplement name and link are required.");
        return;
      }
      const response = await insertNewSupplement(name, link, image);
      console.log("response", response.statusCode);
      if (response.success) {
        alert("New supplement added in list");
        onClose2();
        if (onNewSuppAdded) {
          onNewSuppAdded();
        }
      } else {
        console.log(response);
        alert("Error: " + response.error); // Handle non-successful response
      }
    } catch (error) {
      console.error("API error:", error.message);
      alert("An error occurred while inserting the client.");
    }
  };

  return isOpen2 ? (
    <div className="modal-overlay">
      <div className="modal add-client-modal">
        <div className="modal-content new-supplement-container">
          <h2>
            <i class="fa-solid fa-capsules"></i>Add new supplement
          </h2>
          <div>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <input
              type="text"
              name="link"
              placeholder="Link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          </div>
          <div>
            <input
              type="text"
              name="image"
              placeholder="Image url"
              value={image}
              onChange={(e) => setImage(e.target.value)}
            />
          </div>
          <div className="modal-buttons">
            <button onClick={onClose2} className="button-12">
              Cancel
            </button>
            <button onClick={handleInsertSupplement} className="button-10">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default AddNewSupplementList;
