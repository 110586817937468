import React from "react";
import "../custom-css/TopRow.css"

const TopRow = ({ adminName }) => {
    return (
      <div className="navbar top-row">
        <span>{adminName}</span>
        <i className="fas fa-user"></i>
      </div>
    );
  };
  

  export default TopRow;