import React, { useEffect, useState } from "react";
import "../../custom-css/App.css";
import "../../custom-css/Tables.css";
import "../../custom-css/LeadsDetailsPage.css";
import { getDiscoveryById } from "../../api/discovery_calls";
import ErrorModal from "../../GeneralModal/ErrorSessionModal";
import { formatDateTime } from "../../functions/date_format";
import ModalSetDateDiscovery from "../Modal/SetDateDiscoveryModal";
import { useParams } from "react-router-dom";
import DeleteDiscoveryModal from "../Modal/DeleteDiscoveryModal";

const DiscoveryCallLead = () => {
  const { id } = useParams();
  const [discovery, setDiscovery] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

  const fetchData = async () => {
    try {
      const response = await getDiscoveryById(id); // Fetch client data
      if (response.success) {
        // Assuming the fetched data is in response.data
        setDiscovery(response.data);
      } else {
        // Handle fetch failure
        console.error("Fetching client data failed:", response.error);
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error("Error during client data fetching:", error);
      setShowErrorModal(true);
      // Handle error
    }
  };

  useEffect(() => {
    fetchData(); // Call the fetchData function to fetch client data
  }, [id]);

  const handleButtonDate = () => {
    setIsModalOpen(true);
  };

  // Function to handle closing the modal
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleButtonDelete = () => {
    setIsModalDeleteOpen(true);
  };

  const handleModalDeleteClose = () => {
    setIsModalDeleteOpen(false);
  };

  return (
    <div>
      <div className="discovery-container">
        <div className="discovery-title-row">
          <h3>Discovery Call</h3>
          <div className="btn-title-container">
            {discovery.length === 0 ? (
              <button
                onClick={handleButtonDate}
                className="button-10 add-client-button"
              >
                Set date
              </button>
            ) : (
              <div className="buttons-line-discovery">
                <button
                  onClick={handleButtonDate}
                  className="button-10 add-client-button"
                >
                  Edit Date
                </button>
                <button
                  onClick={handleButtonDelete}
                  className="button-14 add-client-button"
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </div>
        {discovery.length === 0 ? null : (
          <table className="discovery-table">
            <tbody>
              <tr>
                <td className="cell-header profile-table-row-title">Date:</td>
                <td className="table-cell">{formatDateTime(discovery.date)}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <ModalSetDateDiscovery
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onDateSet={fetchData}
        lead_id={id}
      />
      <DeleteDiscoveryModal
        isOpen={isModalDeleteOpen}
        onClose={handleModalDeleteClose}
        lead_id={id}
        onDeleteDone={fetchData}
      />

      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};

export default DiscoveryCallLead;
