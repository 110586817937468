import React from "react";
import { convertLead } from "../../api/insert_lead";

const ModalConvertLead = ({
  isOpen,
  onClose,
  onConvertedDone,
  leadDetails,
  lead_id,
}) => {
  const handleConvert = async () => {
    try {
      const response = await convertLead(leadDetails, lead_id);
      if (response.success) {
        onClose();
        if (onConvertedDone) {
          onConvertedDone();
        }
      } else {
        alert("Error: " + response.error); // Handle non-successful response
      }
    } catch (error) {
      console.error("API error:", error.message);
      alert("An error occurred while inserting the client.");
    }
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal add-package-modal date-modal">
        <div className="modal-content">
          <h2>
            <i class="fa-solid fa-file-signature"></i>Convert Lead
          </h2>
          <p>Are you sure you want to convert this lead to client?</p>

          <div className="modal-buttons">
            <button onClick={onClose} className="button-12">
              Cancel
            </button>
            <button onClick={handleConvert} className="button-10">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ModalConvertLead;
