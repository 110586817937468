// AddClientModal.js
import React, { useState } from "react";

import "../../../custom-css/AddPackageModal.css";
import "../../../custom-css/AddClientModal.css";
import { insertPackage } from "../../../api/insert_package";
import { getLanguageId } from "../../../api/api_urls";

const AddPackageModal = ({ isOpen, onClose, onPackageAdded, client_id }) => {
  const currentDate = new Date(); // Get the current date using new Date()
  const expirationDates = {
    1: new Date(
      currentDate.getFullYear() + 1,
      currentDate.getMonth(),
      currentDate.getDate()
    ).toISOString(), // Add 1 year
    2: new Date(
      currentDate.getFullYear() + 2,
      currentDate.getMonth(),
      currentDate.getDate()
    ).toISOString(), // Add 2 years
    3: new Date(
      currentDate.getFullYear() + 3,
      currentDate.getMonth(),
      currentDate.getDate()
    ).toISOString(), // Add 3 years
  };

  const defaultValidity = expirationDates[1];
  const languageId = getLanguageId();

  const [formData, setFormData] = useState({
    name: "",
    validity: defaultValidity,
    number_consultations: "",
    price: "",
    language: languageId,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    try {
      if (
        formData.name.trim() === "" ||
        formData.validity === "" ||
        formData.number_consultations.trim() === "" ||
        formData.price.trim() === ""
      ) {
        alert("Please complete all form fields.");
        return;
      }

      const response = await insertPackage(client_id, formData);

      if (response.success) {
        // Check the status code for success (e.g., 200 or 201)
        if (response.statusCode === 200 || response.statusCode === 201) {
          // Call the onSuccess function when the API call is successful
          onClose(); // Close the modal
          if (onPackageAdded) {
            onPackageAdded();
          }
        } else {
          alert("Error: " + response); // Handle other success status codes if needed
        }
      } else {
        console.log(response);
        alert("Error: " + response.error); // Handle non-successful response
      }
    } catch (error) {
      console.error("API error:", error.message);
      alert("An error occurred while inserting the client.");
    }
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal add-package-modal">
        <div className="modal-content">
          <h2>
            <i class="fa-solid fa-folder-plus"></i>Add New Package
          </h2>
          <div>
            <input
              type="text"
              name="name"
              placeholder="Package name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="number"
              name="number_consultations"
              placeholder="Consultations"
              value={formData.number_consultations}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="number"
              name="price"
              placeholder="Price"
              value={formData.price}
              onChange={handleChange}
            />
          </div>
          <div className="select-container">
            <label>Validity</label>
            <select
              name="validity"
              value={formData.validity}
              onChange={handleChange}
              className="add-package-select"
            >
              {/* <option value="1">1 year</option>
                <option value="2">2 years</option>
                <option value="3">3 years</option> */}
              {/* Add more options as needed */}
              <option value={expirationDates[1]}>1 year</option>
              <option value={expirationDates[2]}>2 years</option>
              <option value={expirationDates[3]}>3 years</option>
            </select>
          </div>

          <div className="modal-buttons">
            <button onClick={onClose} className="button-12">
              Cancel
            </button>
            <button onClick={handleSave} className="button-10">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default AddPackageModal;
