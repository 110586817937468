// AddClientModal.js
import React, { useEffect, useState } from "react";

import "../../custom-css/AddClientModal.css";
import "../../custom-css/App.css";
import { updateClientProfile } from "../../api/insert_clients";
import { editSupplement } from "../../api/supplements";

const ModalEditSupplement = ({
  isOpen,
  onClose,
  onEditDone,
  initialFormData,
  supplementId,
}) => {
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    // Update the form data when initialFormData changes
    setFormData(initialFormData);
  }, [initialFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEdit = async () => {
    try {
      const response = await editSupplement(
        formData.name,
        formData.link,
        formData.image_url,
        supplementId
      );
      if (response.success) {
        onClose(); // Close the modal
        if (onEditDone) {
          onEditDone();
        }
        alert("Supplement updated correctly!");
      } else {
        alert("Error OR HERE: " + response.error); // Handle non-successful response
      }
    } catch (error) {
      console.error("API error:", error.message);
      alert("An error occurred while inserting the client.");
    }
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal add-client-modal">
        <div className="modal-content">
          <h2>
            <i class="fa-solid fa-user-pen"></i>Edit Client
          </h2>
          <div className="input-edit-profile">
            <label>Supplement name</label>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="input-edit-profile">
            <label>Link</label>
            <input
              type="text"
              name="link"
              placeholder="Link"
              value={formData.link}
              onChange={handleChange}
            />
          </div>
          <div className="input-edit-profile">
            <label>Image link</label>
            <input
              type="text"
              name="image_url"
              placeholder="Image link"
              value={formData.image_url}
              onChange={handleChange}
            />
          </div>
          <div className="modal-buttons">
            <button onClick={onClose} className="button-12">
              Cancel
            </button>
            <button onClick={handleEdit} className="button-10">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ModalEditSupplement;
