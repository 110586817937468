import React, { useState } from "react";
import { setPaid } from "../../../api/insert_package";

const ModalSetPaidPackage = ({ isOpen, onClose, onPaidSet, package_id }) => {
  const [paymentMethod, setPaymentMethod] = useState(1); // State for selected payment method

  const handleSave = async () => {
    // Pass the selected payment method to the onPaidSet function
    // onPaidSet(paymentMethod);
    try {

    
        const response = await setPaid(package_id, paymentMethod);
    
        if (response.success) {
        
          // Check the status code for success (e.g., 200 or 201)
          if (response.statusCode === 200 || response.statusCode === 201) {
            // Call the onSuccess function when the API call is successful
            onClose(); // Close the modal
            if (onPaidSet) {
                onPaidSet();
            }
            
          } else {
            alert("Error: " + response); // Handle other success status codes if needed
          }
        } else {
          console.log(response);
          alert("Error: " + response.error); // Handle non-successful response
        }
      } catch (error) {
        console.error("API error:", error.message);
        alert("An error occurred while inserting the client.");
      }
  };

  const handleChange = (e) => {
    setPaymentMethod(e.target.value); // Update the selected payment method
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal add-package-modal date-modal">
        <div className="modal-content">
          <h2><i className="fa-solid fa-hand-holding-dollar"></i>Set paid</h2>
          <div className="date-picker-container">
            <p>Are you sure you want to set paid the package?</p>
          </div>
          <label htmlFor="paymentMethod">Payment Method</label>
          <div className="select-container">

          <select
            id="paymentMethod"
            name="payment_method"
            value={paymentMethod}
            onChange={handleChange}
            className="add-package-select"
          >

            <option value="1">Revolut</option>
            <option value="2">Stripe</option>
            <option value="3">Other</option>
            {/* Add more options as needed */}
          </select>
          </div>
          <div className="modal-buttons">
            <button onClick={onClose} className="button-12">Cancel</button>
            <button onClick={handleSave} className="button-10">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ModalSetPaidPackage;
