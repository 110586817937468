// UserDetails.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TopRow from "../../TopRow/TopRow";
import "../../custom-css/UserDetails.css";
import { getPackageDetails } from "../../api/get_package_details";
import PackageDetailsTable from "./PackageDetailsTable";
import PackageConsultationsTable from "./PackageConsultationsTable";
import ErrorModal from "../../GeneralModal/ErrorSessionModal";

const PackagePage = ({ }) => {
  const { id } = useParams();
  const [packageDetails, setPackageDetails] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const fetchData = async () => {
    try {
      const response = await getPackageDetails(id); // Fetch client data
      if (response.success) {
        // Assuming the fetched data is in response.data
        setPackageDetails(response.data);
      } else {
        // Handle fetch failure
        console.error("Fetching client data failed:", response.error);
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error("Error during client data fetching:", error);
      setShowErrorModal(true);
      // Handle error
    }
  };
  useEffect(() => {
    fetchData(); // Call the fetchData function to fetch client data
  }, [id]);

  const updatePackage = () => {
    fetchData();
  };

  return (
    <div className="user-details-main-container">
      <div className="navbar">
        <TopRow adminName={"Admin"} />
      </div>
      <div className="user-details-internal-container">
        <h2>
          {packageDetails?.name} / {packageDetails?.client_name} /{" "}
          {packageDetails?.id}
        </h2>

        <div className="user-details-first-row">
          <PackageDetailsTable
            packageDetails={packageDetails}
            updatePackage={updatePackage}
          />
          <PackageConsultationsTable />
        </div>
      </div>
      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};

export default PackagePage;
