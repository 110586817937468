// AddClientModal.js
import React, { useState } from "react";

import "../../custom-css/AddClientModal.css";
import "../../custom-css/App.css";
import { insertLeadNote, insertNoteClient } from "../../api/notes";

const AddNoteModal = ({ isOpen, onClose, onNoteAdded, entityId, type }) => {
  const [note, setNote] = useState("");

  const handleSave = async () => {
    try {
      if (note.trim() === "") {
        alert("Please insert a note to save");
        return;
      }

      let response;
      console.log(type);
      if (type === "client") {
        response = await insertNoteClient(entityId, note);
      } else {
        response = await insertLeadNote(entityId, note);
      }

      if (response.success) {
        // Check the status code for success (e.g., 200 or 201)

        alert("Note added correctly");
        onClose();
        if (onNoteAdded) {
          onNoteAdded();
        }
      } else {
        console.log(response);
        alert("Error: " + response.error); // Handle non-successful response
      }
    } catch (error) {
      console.error("API error:", error.message);
      alert("An error occurred while inserting the client.");
    }
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal add-client-modal">
        <div className="modal-content">
          <h2>
            <i class="fa-solid fa-circle-plus"></i>Add New Note
          </h2>
          <div>
            <textarea
              name="note"
              placeholder="Write you note here...."
              value={note}
              onChange={(e) => setNote(e.target.value)}
              rows={8}
            />
          </div>
          <div className="modal-buttons">
            <button onClick={onClose} className="button-12">
              Cancel
            </button>
            <button onClick={handleSave} className="button-10">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default AddNoteModal;
