import React, { useEffect, useState } from "react";
import TopRow from "../TopRow/TopRow";

import TitleTables from "../GenericComponents/TitleTables";

import ErrorModal from "../GeneralModal/ErrorSessionModal";
import { getAllSupplements } from "../api/supplements";
import SupplementsTable from "./SupplementsTable";
import AddNewSupplementList from "../Consultations/ConsultationDetails/Modal/ModalNewSupplementList";

const SupplementsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [supplements, setSupplements] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    // Fetch and set users data from the imported JSON file
    getSupplements();
  }, []);

  const handleAddSupp = () => {
    // Open the modal when the "Add New Client" button is clicked
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    // Close the modal
    setIsModalOpen(false);
  };

  const getSupplements = async () => {
    try {
      const response = await getAllSupplements();
      if (response.success) {
        // Assuming the fetched data is in response.data
        setSupplements(response.data);
      } else {
        // Handle fetch failureshowErrorModal("An error occurred while fetching data.");
        console.error("Fetching clients failed:", response.error);
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error("Error during client data fetching:", error);
      setShowErrorModal(true);
      // Handle error
    }
  };

  return (
    <div className="users-scrollable-container">
      <div className="users">
        <div className="navbar">
          <TopRow adminName={"Admin"} />
        </div>
        <div className="users-container">
          <TitleTables
            title={"Supplements"}
            buttonText={"Add new supplement"}
            onClick={handleAddSupp}
          />
          <SupplementsTable
            supplements={supplements}
            refreshTable={getSupplements}
          />
          {/* {supplements.length > 0 ? (
          ) : (
            <p>Loading...</p>
          )} */}
        </div>
      </div>
      <AddNewSupplementList
        isOpen2={isModalOpen}
        onClose2={handleModalClose}
        onNewSuppAdded={getSupplements}
      />
      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};

export default SupplementsPage;
