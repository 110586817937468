import React, { useState } from "react";
import "../custom-css/UserTable.css";
import DeleteSupplementModal from "./Modal/DeleteSupplementModal";
import { Link } from "react-router-dom";
import ModalEditSupplement from "./Modal/ModalEditSupplement";

const SupplementsTable = ({ supplements, refreshTable }) => {
  const [searchQuery, setSearchQuery] = useState(""); // State variable for search query
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15; // Number of items to display per page
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSupplement, setSelectedSupplement] = useState("");

  const handleDeleteSupp = (supp) => {
    // Open the modal when the "Add New Client" button is clicked
    setSelectedSupplement(supp);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    // Close the modal
    setIsModalOpen(false);
  };

  // Filter the users based on the search query
  const filteredSupplements = supplements.filter((supplement) =>
    supplement.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredSupplements.length / itemsPerPage);

  // Function to generate an array of visible page numbers
  const getVisiblePageNumbers = () => {
    const maxPageButtons = Math.ceil(supplements.length / 10) + 1; // Number of page buttons to display

    if (totalPages <= maxPageButtons) {
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    } else {
      const halfButtons = Math.floor(maxPageButtons / 2);
      const firstVisiblePage = Math.max(1, currentPage - halfButtons);
      const lastVisiblePage = Math.min(totalPages, currentPage + halfButtons);

      return Array.from(
        { length: lastVisiblePage - firstVisiblePage + 1 },
        (_, index) => firstVisiblePage + index
      );
    }
  };

  // Handle changes in the search input
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when the search query changes
  };

  // Handle pagination button clicks
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      {/* Search bar */}
      <div className="search-user">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          placeholder="Search by supplement name"
          value={searchQuery}
          onChange={handleSearchInputChange}
          className="search-bar"
        />
      </div>
      <div>
        <table className="table-users">
          <thead>
            <tr>
              <th className="table-header">ID</th>
              <th className="table-header">Name</th>
              <th className="table-header">Link</th>
              <th className="table-header">Image</th>
              <th className="table-header">Use</th>
              <th className="table-header"></th>
            </tr>
          </thead>
          <tbody>
            {filteredSupplements
              .slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
              )
              .map((supplement) => (
                <tr key={supplement.id}>
                  <td className="table-cell">
                    <Link
                      to={`/supplements/details/${supplement.id}`}
                      className="user-link"
                    >
                      {supplement.id}
                    </Link>
                  </td>
                  <td className="table-cell">{supplement.name}</td>
                  <td className="table-cell">
                    <Link
                      to={`${supplement.link}`}
                      className="user-link"
                      target="_blank"
                    >
                      {supplement.link}
                    </Link>
                  </td>
                  <td className="table-cell">
                    <img
                      src={supplement.image_url}
                      className="supplement-image"
                    ></img>
                  </td>
                  <td className="table-cell">
                    {" "}
                    {supplement.use ? (
                      <i class="fa-solid fa-check use-supplement-icon-true"></i>
                    ) : (
                      <i class="fa-solid fa-xmark use-supplement-icon-false"></i>
                    )}
                  </td>
                  <td className="table-cell">
                    <div className="cell-delete">
                      <button
                        onClick={() => handleDeleteSupp(supplement)}
                        className="button-12"
                      >
                        <i class="fa-solid fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* Pagination */}
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn-pagination"
        >
          Previous
        </button>
        {getVisiblePageNumbers().map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={
              currentPage === page
                ? "btn-pagination btn-pagination-active"
                : "btn-pagination"
            }
          >
            {page}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="btn-pagination"
        >
          Next
        </button>
      </div>
      <DeleteSupplementModal
        supplement={selectedSupplement}
        isOpen2={isModalOpen}
        onClose2={handleModalClose}
        onDeletedSupp={refreshTable}
      />
    </div>
  );
};

export default SupplementsTable;
