import React, { useEffect, useState } from "react";
import TopRow from "../TopRow/TopRow";
import LeadsTable from "./LeadsTable";
import AddLeadModal from "./Modal/AddLeadModal";
import { getLeads } from "../api/get_leads";
import ErrorModal from "../GeneralModal/ErrorSessionModal";
import "../custom-css/LeadsDetailsPage.css";

const Leads = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [leadsData, setLeads] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showConverted, setShowConverted] = useState(false);

  useEffect(() => {
    // Fetch client data when the component mounts
    handleLeadAdded();
  }, []);

  useEffect(() => {
    // Fetch and set users data from the imported JSON file
    setLeads(leadsData);
  }, []);
  const handleAddLead = () => {
    // Open the modal when the "Add New Client" button is clicked
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    // Close the modal
    setIsModalOpen(false);
  };

  const handleLeadAdded = async () => {
    try {
      const response = await getLeads();
      if (response.success) {
        // Assuming the fetched data is in response.data
        setLeads(response.data);
      } else {
        // Handle fetch failureshowErrorModal("An error occurred while fetching data.");

        console.error("Fetching clients failed:", response.error);
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error("Error during client data fetching:", error);
      setShowErrorModal(true);
      // Handle error
    }
  };
  return (
    <div className="users-scrollable-container">
      <div className="users">
        <div className="navbar">
          <TopRow adminName={"Admin"} />
        </div>
        <div className="users-container">
          <div className="title-table-notes">
            <h3>Leads</h3>
            <div className="btn-title-container">
              <button
                onClick={handleAddLead}
                className="button-10 add-client-button"
              >
                Add new lead
              </button>
              <button
                onClick={() => setShowConverted(!showConverted)}
                className="button-10 btn-show-all"
              >
                {showConverted ? "Hide converted" : "Show converted"}
              </button>
            </div>
          </div>

          <LeadsTable
            leadsDetails={
              showConverted
                ? leadsData
                : leadsData.filter((lead) => lead.converted === false)
            }
            showConverted={showConverted}
          />
        </div>
      </div>
      <AddLeadModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onClientAdded={handleLeadAdded}
      />
      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};

export default Leads;
