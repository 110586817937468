// AddClientModal.js
import React, { useEffect, useState } from "react";
import "../../custom-css/AddClientModal.css";
import "../../custom-css/App.css";
import { insertRecord, uploadRecord } from "../../api/records";
import { getClients } from "../../api/get_clients";
import ErrorModal from "../../GeneralModal/ErrorSessionModal";
import { domain } from "../../api/api_urls";

const ModalAddRecord = ({
  isOpen,
  onClose,
  onFileAdded,
  clientId: propClientId,
}) => {
  const [type, setType] = useState(1);
  const [users, setUsers] = useState([]);
  const [clientId, setClientId] = useState(propClientId || null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    if (!propClientId) {
      fetchClients();
    }
  }, []);

  const handleChangeType = (event) => {
    setType(event.target.value);
  };

  const handleSelectClient = (event) => {
    setClientId(event.target.value);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const createPath = (linkPath) => {
    const uploadsIndex = linkPath.indexOf("uploads");

    // Get the substring starting from "uploads"
    const relativePath = linkPath.substring(uploadsIndex);

    const url = domain + relativePath;

    return url;
  };

  const handleSave = async () => {
    try {
      if (clientId === 0) {
        return alert("Please select a client");
      }

      if (selectedFile === null) {
        return alert("Pleas select a file");
      }
      const formData = new FormData();
      formData.append("file", selectedFile);
      const response = await uploadRecord(clientId, formData);

      if (response.success) {
        if (response.status === 401) {
          setShowErrorModal(true);
        }
        if (response.status === 200 || response.status === 201) {
          await insertRecord(
            clientId,
            type,
            createPath(response.data.filepath)
          );
          onClose();
          if (onFileAdded) {
            onFileAdded();
          }
          return alert("Record added correctly");
        } else {
          alert("Error this one: " + response); // Handle other success status codes if needed
        }
      } else {
        alert("Error or this other: " + response.error); // Handle non-successful response
      }
    } catch (error) {
      setShowErrorModal(true);
      //   alert("An error occurred while inserting the client.");
    }
  };

  const fetchClients = async () => {
    try {
      const response = await getClients(); // Fetch client data
      if (response.success) {
        // Assuming the fetched data is in response.data
        setUsers(response.data);
        setClientId(response.data[0].id);
      } else {
        setShowErrorModal(true);
        // Handle fetch failure
        console.error("Fetching client data failed:", response.error);
      }
    } catch (error) {
      setShowErrorModal(true);
      console.error("Error during client data fetching:", error);
      // Handle error
    }
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal add-client-modal">
        <div className="modal-content">
          <h2>
            <i class="fa-solid fa-user-plus"></i>Add New Lead
          </h2>
          <div className="select-container">
            <label>Record type</label>
            <select
              name="type"
              value={type}
              onChange={handleChangeType}
              className="add-package-select"
            >
              <option value={1}>Blood Test</option>
              <option value={2}>Other</option>
            </select>
          </div>
          {propClientId ? null : (
            <div className="select-container">
              <label>Client</label>
              <select
                name="client"
                value={clientId}
                onChange={handleSelectClient}
                className="add-package-select"
              >
                {users.length === 0
                  ? null
                  : users.map((user) => (
                      <option value={user.id}>{user.name}</option>
                    ))}
              </select>
            </div>
          )}
          <div className="file-input-container">
            <label>Select File</label>
            <input
              type="file"
              onChange={handleFileChange}
              accept=".pdf,.png,.jpeg"
            />
          </div>
          <div className="modal-buttons">
            <button onClick={onClose} className="button-12">
              Cancel
            </button>
            <button onClick={handleSave} className="button-10">
              Save
            </button>
          </div>
        </div>
      </div>
      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  ) : null;
};

export default ModalAddRecord;
