// ProfileTable.js
import React, { useState } from "react";
import "../../custom-css/App.css";
import "../../custom-css/Records.css";
import "../../custom-css/Supplements.css";

const SupplementDetailsTable = ({ supplementDetails }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDelete = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onRecordDeleted = () => {
    window.history.back();
  };

  return (
    <div className="supplement-details-container">
      <table className="records-table">
        <tbody>
          <tr>
            <td className="cell-header profile-table-row-title">ID:</td>
            <td className="table-cell">{supplementDetails?.id}</td>
          </tr>
          <tr>
            <td className="cell-header profile-table-row-title">Name:</td>
            <td className="table-cell">{supplementDetails?.name}</td>
          </tr>
          <tr>
            <td className="cell-header profile-table-row-title">Link:</td>
            <td className="table-cell">
              <span
                onClick={() => window.open(supplementDetails.link, "_blank")}
                className="link-supplement-details"
              >
                {supplementDetails?.name}
              </span>
            </td>
          </tr>
          <tr>
            <td className="cell-header profile-table-row-title">In use:</td>
            <td className="table-cell">
              {supplementDetails?.use ? (
                <i class="fa-solid fa-check use-supplement-icon-true"></i>
              ) : (
                <i class="fa-solid fa-xmark use-supplement-icon-false"></i>
              )}
            </td>
          </tr>
          {/* <tr>
            <td className="cell-header profile-table-row-title">File:</td>
            <td className="table-cell file-record-table">
              <button
                onClick={() => window.open(supplementDetails.link, "_blank")}
                className="button-10"
              >
                Open file
              </button>
              <button onClick={handleDelete} className="button-14">
                Delete
              </button>
            </td>
          </tr> */}
        </tbody>
      </table>

      {/* <ModalDeleteRecord
        isOpen={isModalOpen}
        onClose={closeModal}
        recordId={recordDetails.id}
        onRecordDeleted={onRecordDeleted}
      /> */}
    </div>
  );
};

export default SupplementDetailsTable;
