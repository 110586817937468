// AddClientModal.js
import React, { useEffect, useState } from "react";
import "../../custom-css/AddClientModal.css";
import "../../custom-css/App.css";
import { upload } from "@testing-library/user-event/dist/upload";
import { deleteRecord, insertRecord, uploadRecord } from "../../api/records";
import { getClients } from "../../api/get_clients";
import ErrorModal from "../../GeneralModal/ErrorSessionModal";
import { domain } from "../../api/api_urls";

const ModalDeleteRecord = ({ recordId, isOpen, onClose, onRecordDeleted }) => {
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleDeleteRecord = async () => {
    try {
      const response = await deleteRecord(recordId);

      if (response.success) {
        if (response.status === 401) {
          setShowErrorModal(true);
        }
        if (response.status === 200 || response.status === 201) {
          onClose();
          if (onRecordDeleted) {
            onRecordDeleted();
          }
          return alert("Record deleted correctly");
        } else {
          alert("Error this one: " + response); // Handle other success status codes if needed
        }
      } else {
        alert("Error or this other: " + response.error); // Handle non-successful response
      }
    } catch (error) {
      setShowErrorModal(true);
      //   alert("An error occurred while inserting the client.");
    }
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal add-client-modal">
        <div className="modal-content">
          <h2>
            <i class="fa-solid fa-user-plus"></i>Are you sure you want to delete
            this record?
          </h2>

          <div className="modal-buttons">
            <button onClick={onClose} className="button-12">
              Cancel
            </button>
            <button onClick={handleDeleteRecord} className="button-10">
              Confirm
            </button>
          </div>
        </div>
      </div>
      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  ) : null;
};

export default ModalDeleteRecord;
