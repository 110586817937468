import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDateTime } from "../../functions/date_format";
import { setDateDiscovery } from "../../api/discovery_calls";

const ModalSetDateDiscovery = ({ isOpen, onClose, onDateSet, lead_id }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const today = new Date();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSetDate = async () => {
    if (selectedDate === null) {
      alert("You need to select a date");
      return;
    }

    try {
      const response = await setDateDiscovery(lead_id, selectedDate); // Fetch client data
      if (response.success) {
        alert(`Consultation set for day ${formatDateTime(selectedDate)}`);
        onClose();
        if (onDateSet) {
          onDateSet();
        }
      } else {
        // Handle fetch failure
        onClose();
        alert("There has been an error. Please try again.");
        console.error("Fetching client data failed:", response.error);
      }
    } catch (error) {
      onClose();
      alert("There has been an error. Please try again.");
      console.error("Error during client data fetching:", error);
      // Handle error
    }
  };

  const handleSaveClick = () => {
    handleSetDate();
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal add-package-modal date-modal">
        <div className="modal-content">
          <h2>
            <i class="fa-solid fa-calendar-days"></i>Choose Date
          </h2>
          <div className="date-picker-container">
            <DatePicker
              minDate={today + 1}
              selected={selectedDate}
              onChange={handleDateChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="d MMMM yyyy - HH:mm"
              placeholderText="Select date and time"
            />
          </div>

          <div className="modal-buttons">
            <button onClick={onClose} className="button-12">
              Cancel
            </button>
            <button onClick={handleSaveClick} className="button-10">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ModalSetDateDiscovery;
