// ProfileTable.js
import React, { useState } from "react";
import "../custom-css/App.css";
import "../custom-css/Records.css";
import ModalDeleteRecord from "./Modal/ModalDeleteRecord";

const RecordDetailsTable = ({ recordDetails }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDelete = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onRecordDeleted = () => {
    window.history.back();
  };

  return (
    <div className="record-details-container">
      <table className="records-table">
        <tbody>
          <tr>
            <td className="cell-header profile-table-row-title">ID:</td>
            <td className="table-cell">{recordDetails?.id}</td>
          </tr>
          <tr>
            <td className="cell-header profile-table-row-title">Name:</td>
            <td className="table-cell">{recordDetails?.client_name}</td>
          </tr>
          <tr>
            <td className="cell-header profile-table-row-title">File:</td>
            <td className="table-cell file-record-table">
              <button
                onClick={() => window.open(recordDetails.link, "_blank")}
                className="button-10"
              >
                Open file
              </button>
              <button onClick={handleDelete} className="button-14">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <ModalDeleteRecord
        isOpen={isModalOpen}
        onClose={closeModal}
        recordId={recordDetails.id}
        onRecordDeleted={onRecordDeleted}
      />
    </div>
  );
};

export default RecordDetailsTable;
