import React, { useState, useEffect } from "react";

import ErrorModal from "../../GeneralModal/ErrorSessionModal";
import "../../custom-css/IntakeForm.css";
import IntakeFormData from "./IntakeForm/Form";

const IntakeForm = ({ userDetails, updateClient }) => {
  const [intakeForm, setIntakeForm] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleExpanded = () => {
    setExpanded(!expanded);
  };
  return (
    <div className="notes-table">
      <div className="title-container-expandable">
        <div className="title-table-notes-expandable">
          <h3>Intake Form</h3>
          {userDetails.completed_intake ? (
            <div className="intake-completed-note">
              <i class="fa-solid fa-check"></i>
              <span>Completed</span>
            </div>
          ) : (
            <div className="intake-completed-note">
              <i class="fa-solid fa-xmark"></i>
              <span>Not completed</span>
            </div>
          )}
        </div>
        <button onClick={handleExpanded} className="button-13">
          <p>{expanded ? "See less" : "See more"}</p>
          <i className={`fa-solid fa-chevron-${expanded ? "up" : "down"}`}></i>
        </button>
      </div>
      {expanded ? (
        <IntakeFormData
          clientDetails={userDetails}
          updateClient={updateClient}
        />
      ) : null}

      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};

export default IntakeForm;
