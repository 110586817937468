import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../custom-css/UserTable.css";
import { formatDate } from "../functions/date_format";

const ConsultationsTable = ({ consultations }) => {
  const [currentPage, setCurrentPage] = useState(1); // State variable for current page
  const itemsPerPage = 15; // Number of items to display per page

  // Calculate the total number of pages
  const totalPages = Math.ceil(consultations.length / itemsPerPage);

  // Function to generate an array of visible page numbers
  const getVisiblePageNumbers = () => {
    const maxPageButtons = Math.ceil(consultations.length / 10) + 1;

    if (totalPages <= maxPageButtons) {
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    } else {
      const halfButtons = Math.floor(maxPageButtons / 2);
      const firstVisiblePage = Math.max(1, currentPage - halfButtons);
      const lastVisiblePage = Math.min(totalPages, currentPage + halfButtons);

      return Array.from(
        { length: lastVisiblePage - firstVisiblePage + 1 },
        (_, index) => firstVisiblePage + index
      );
    }
  };

  // Serch name

  // Handle pagination button clicks
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <table className="table-users">
        <thead>
          <tr>
            <th className="table-header">ID</th>
            <th className="table-header">Order</th>
            <th className="table-header">Client</th>
            <th className="table-header">Package</th>
            <th className="table-header">Date</th>
            <th className="table-header">Status</th>
          </tr>
        </thead>
        <tbody>
          {consultations
            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
            .map((consultation) => (
              <tr key={consultation.id}>
                <td className="table-cell">
                  <Link
                    to={`/consultation/details/${consultation.id}`}
                    className="user-link"
                  >
                    {consultation.id}
                  </Link>
                </td>
                <td className="table-cell">{consultation.order}</td>
                <td className="table-cell">
                  <Link
                    to={`/user/details/${consultation.client_id}`}
                    className="user-link"
                  >
                    {consultation.client_name}
                  </Link>
                </td>
                {/* <td className="table-cell">{consultation.client_name}</td> */}
                <td className="table-cell">
                  {/* <Link
                    to={`/consultation/details/${consultation.id}`}
                    className="user-link"
                  >
                    {consultation.id}
                  </Link>   */}
                  {consultation.package_name}
                </td>
                <td className="table-cell">
                  {consultation.date === null
                    ? "Not set yet"
                    : formatDate(consultation.date)}
                </td>
                <td className="table-cell">{consultation.status}</td>
              </tr>
            ))}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn-pagination"
        >
          Previous
        </button>
        {getVisiblePageNumbers().map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={
              currentPage === page
                ? "btn-pagination btn-pagination-active"
                : "btn-pagination"
            }
          >
            {page}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="btn-pagination"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ConsultationsTable;
