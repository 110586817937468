
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../custom-css/UserTable.css";
import {  formatDateTime } from "../functions/date_format";


const DiscoveryCallsTable = ({ discoveryCalls }) => {
    const [searchQuery, setSearchQuery] = useState(""); // State variable for search query
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15; // Number of items to display per page




    // Filter the users based on the search query
    // const discoveryCalls = discoveryCalls.filter((user) =>
    //     user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //     user.phone_number.includes(searchQuery.toLowerCase()) ||
    //     user.email_address.toLowerCase().includes(searchQuery.toLowerCase())
    // );

    // Calculate the total number of pages
    const totalPages = Math.ceil(discoveryCalls.length / itemsPerPage);

    // Function to generate an array of visible page numbers
    const getVisiblePageNumbers = () => {
        const maxPageButtons = Math.ceil(discoveryCalls.length / 10) + 1;// Number of page buttons to display

        if (totalPages <= maxPageButtons) {
            return Array.from({ length: totalPages }, (_, index) => index + 1);
        } else {
            const halfButtons = Math.floor(maxPageButtons / 2);
            const firstVisiblePage = Math.max(1, currentPage - halfButtons);
            const lastVisiblePage = Math.min(
                totalPages,
                currentPage + halfButtons
            );

            return Array.from(
                { length: lastVisiblePage - firstVisiblePage + 1 },
                (_, index) => firstVisiblePage + index
            );
        }
    };

    // Handle changes in the search input
    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when the search query changes
    };

    // Handle pagination button clicks
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div>
            {/* Search bar */}
            {/* <div className="search-user">
                <i class="fa-solid fa-magnifying-glass"></i>
                <input
                    type="text"
                    placeholder="Search by la"
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    className="search-bar"
                />
            </div> */}
            <table className="table-users">
                <thead>
                    <tr>
                        <th className="table-header">ID</th>
                        <th className="table-header">Lead Name</th>
                        <th className="table-header">Date</th>
                        
                    </tr>
                </thead>
                <tbody>
                    {discoveryCalls
                        .slice(
                            (currentPage - 1) * itemsPerPage,
                            currentPage * itemsPerPage
                        )
                        .map((discovery) => (
                            <tr key={discovery.id}>
                                <td className="table-cell">{discovery.id}</td>
                                <td className="table-cell">
                                    <Link to={`/leads/details/${discovery.lead_id}`} className="user-link">
                                        {discovery.lead_name}
                                    </Link>
                                </td>
                                <td className="table-cell">{formatDateTime(discovery.date)}</td>
                                
                            </tr>
                        ))}
                </tbody>
            </table>
            {/* Pagination */}
            <div className="pagination">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="btn-pagination"
                >
                    Previous
                </button>
                {getVisiblePageNumbers().map((page) => (
                    <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={currentPage === page ? "btn-pagination btn-pagination-active" : "btn-pagination"}
                    >
                        {page}
                    </button>
                ))}
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="btn-pagination"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default DiscoveryCallsTable;
