import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import "../../custom-css/ProfileTable.css";
import { formatDate } from "../../functions/date_format";
import { getConsultationsByPackage } from "../../api/get_consultations_package";
import ErrorModal from "../../GeneralModal/ErrorSessionModal";

const PackageConsultationsTable = ({ }) => {
    const { id } = useParams();
    const [consultationsClient, setConsultationsClient] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const itemsPerPage = 10; // Number of items to display per page

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getConsultationsByPackage(id); // Fetch client data
                if (response.success) {
                    // Assuming the fetched data is in response.data as an array of objects
                    setConsultationsClient(response.data);
                } else {
                    // Handle fetch failure
                    console.error("Fetching client data failed:", response.error);
                    setShowErrorModal(true);
                }
            } catch (error) {
                console.error("Error during client data fetching:", error);
                setShowErrorModal(true);
                // Handle error
            }
        };

        fetchData(); // Call the fetchData function to fetch client data
    }, [id]);






    // Calculate the total number of pages
    const totalPages = Math.ceil(consultationsClient.length / itemsPerPage);

    // Function to generate an array of visible page numbers
    const getVisiblePageNumbers = () => {
        const maxPageButtons = Math.ceil(consultationsClient.length / 5) + 1;// Number of page buttons to display

        if (totalPages <= maxPageButtons) {
            return Array.from({ length: totalPages }, (_, index) => index + 1);
        } else {
            const halfButtons = Math.floor(maxPageButtons / 2);
            const firstVisiblePage = Math.max(1, currentPage - halfButtons);
            const lastVisiblePage = Math.min(
                totalPages,
                currentPage + halfButtons
            );

            return Array.from(
                { length: lastVisiblePage - firstVisiblePage + 1 },
                (_, index) => firstVisiblePage + index
            );
        }
    };



    // Handle pagination button clicks
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="profile-table-container">
            <div className="title-table-notes">
                <h3>Consultations</h3>
            </div>
            <table className="profile-table">

                <tbody>
                    <tr>
                        <td className="notes-table-column-title">ID</td>
                        <td className="notes-table-column-title">Date</td>
                        <td className="notes-table-column-title">Package</td>
                    </tr>
                    {consultationsClient.slice(
                        (currentPage - 1) * itemsPerPage,
                        currentPage * itemsPerPage
                    ).map((item) => (
                        <tr key={item.id}>
                            <td className="table-cell">
                                <Link to={`/consultation/details/${item.id}`} className="user-link">
                                    {item.id}
                                </Link>
                            </td>
                            <td className="table-cell">{item.date === null ? 'Not set yet' : formatDate(item.date)}</td>
                            <td className="table-cell">{item.package_name}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="btn-pagination"
                >
                    Previous
                </button>
                {getVisiblePageNumbers().map((page) => (
                    <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={currentPage === page ? "btn-pagination btn-pagination-active" : "btn-pagination"}
                    >
                        {page}
                    </button>
                ))}
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="btn-pagination"
                >
                    Next
                </button>
            </div>
            <ErrorModal
                show={showErrorModal}
                onClose={() => setShowErrorModal(false)}
            />
        </div>
    );
};

export default PackageConsultationsTable;
