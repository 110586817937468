import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../custom-css/Login.css";
import { loginClient } from "../api/clients_api";

const ClientPageLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await loginClient(username, password);

      if (response.success) {
        // Redirect to the dashboard on successful login
        const { session_id } = response.data;
        localStorage.setItem("session-id", session_id);
        // localStorage.setItem("date-test", date);

        navigate(`/clients/${session_id}`);
      } else {
        alert("Invalid username or password");
      }
    } catch (error) {
      console.error("ClientPageLogin error:", error.message);
      alert("An error occurred while logging in.");
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>ClientPageLogin</h2>
        <div>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="text"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="row-btn-login">
          <button onClick={handleLogin} className="btn-login button-10">
            Enter
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClientPageLogin;
