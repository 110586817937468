import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../../custom-css/ProfileTable.css";
import { formatDate } from "../../functions/date_format";

const ConsultationsTable = ({ consultations }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [expanded, setExpanded] = useState(false);
  const itemsPerPage = 5; // Number of items to display per page

  // Calculate the total number of pages
  const totalPages = Math.ceil(consultations.length / itemsPerPage);

  // Function to generate an array of visible page numbers
  const getVisiblePageNumbers = () => {
    const maxPageButtons = Math.ceil(consultations.length / 5) + 1; // Number of page buttons to display

    if (totalPages <= maxPageButtons) {
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    } else {
      const halfButtons = Math.floor(maxPageButtons / 2);
      const firstVisiblePage = Math.max(1, currentPage - halfButtons);
      const lastVisiblePage = Math.min(totalPages, currentPage + halfButtons);

      return Array.from(
        { length: lastVisiblePage - firstVisiblePage + 1 },
        (_, index) => firstVisiblePage + index
      );
    }
  };

  // Handle pagination button clicks
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="profile-table-container">
      <div className="title-container-expandable">
        <div className="title-table-notes-expandable">
          <h3>Consultations</h3>
        </div>
        <button onClick={handleExpanded} className="button-13">
          <p>{expanded ? "See less" : "See more"}</p>
          <i className={`fa-solid fa-chevron-${expanded ? "up" : "down"}`}></i>
        </button>
      </div>
      {expanded && consultations.length === 0 ? (
        <p>No consultations yet</p>
      ) : expanded ? (
        <React.Fragment>
          <table className="profile-table">
            <tbody>
              <tr>
                <td className="notes-table-column-title">ID</td>
                <td className="notes-table-column-title">Date</td>
                <td className="notes-table-column-title">Package</td>
                <td className="notes-table-column-title">Status</td>
              </tr>
              {consultations
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((item) => (
                  <tr key={item.id}>
                    <td className="table-cell">
                      <Link
                        to={`/consultation/details/${item.id}`}
                        className="user-link"
                      >
                        {item.id}
                      </Link>
                    </td>
                    <td className="table-cell">
                      {item.date === null
                        ? "Not set yet"
                        : formatDate(item.date)}
                    </td>
                    <td className="table-cell">{item.package_name}</td>
                    <td className="table-cell">{item.status}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="btn-pagination"
            >
              Previous
            </button>
            {getVisiblePageNumbers().map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={
                  currentPage === page
                    ? "btn-pagination btn-pagination-active"
                    : "btn-pagination"
                }
              >
                {page}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="btn-pagination"
            >
              Next
            </button>
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default ConsultationsTable;
