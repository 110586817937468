import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "./api/login"; // Import the login function
import "./custom-css/Login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await login(username, password);

      if (response.success) {
        // Redirect to the dashboard on successful login
        const { admin_id, session_id, name } = response.data;
        localStorage.setItem("admin_id", admin_id);
        localStorage.setItem("session_id", session_id);
        // localStorage.setItem("date-test", date);

        navigate("/dashboard");
      } else {
        alert("Invalid username or password");
      }
    } catch (error) {
      console.error("Login error:", error.message);
      alert("An error occurred while logging in.");
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>Login</h2>
        <div>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="text"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="row-btn-login">
          <button onClick={handleLogin} className="btn-login button-10">
            Enter
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
