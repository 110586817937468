import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TopRow from "../TopRow/TopRow";
import "../custom-css/UserDetails.css";
import { getRecordById } from "../api/records";
import ErrorModal from "../GeneralModal/ErrorSessionModal";
import RecordDetailsTable from "./RecordDetailsTable";
import ModalDeleteRecord from "./Modal/ModalDeleteRecord";
import PreviewComponent from "../GenericComponents/PreviewComponent";

const RecordDetails = () => {
  const { id } = useParams();
  const [recordDetails, setRecordDetails] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    fetchRecord();
  }, [id]);

  const fetchRecord = async () => {
    try {
      const response = await getRecordById(id);
      if (response.success) {
        setRecordDetails(response.data);
      } else {
        // Handle error
        console.error("Failed to fetch appointments:");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching appointments:", error);

      setShowErrorModal(true);
    }
  };

  return (
    <div className="user-details-main-container">
      <div className="navbar">
        <TopRow adminName={"Admin"} />
      </div>
      <div className="user-details-internal-container">
        <h2>
          Record / {id} / {recordDetails?.type}
        </h2>
        <div className="user-details-first-row"></div>
      </div>
      {recordDetails === null ? null : (
        <div>
          <RecordDetailsTable recordDetails={recordDetails} />
          <div>
            <PreviewComponent link={recordDetails.link} />
          </div>
        </div>
      )}

      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};

export default RecordDetails;
