import React, { useEffect, useState } from "react";
import TopRow from "../TopRow/TopRow";
import { getAllRecords } from "../api/records";
import ErrorModal from "../GeneralModal/ErrorSessionModal";
import RecordsTable from "./RecordsTable";
import "../custom-css/Records.css";
import ModalAddRecord from "./Modal/ModalAddRecord";

const Records = () => {
  const [records, setRecords] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchRecords();
  }, []);

  const handleAddRecord = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const fetchRecords = async () => {
    try {
      const response = await getAllRecords();
      if (response.success) {
        setRecords(response.data);
      } else {
        // Handle error
        console.error("Failed to fetch appointments:");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching appointments:", error);

      setShowErrorModal(true);
    }
  };

  return (
    <div className="users-scrollable-container">
      <div className="users">
        <div className="navbar">
          <TopRow adminName={"Admin"} />
        </div>
        <div className="users-container">
          <div className="records-header">
            <h2>Records</h2>
            <button
              onClick={handleAddRecord}
              className="button-10 btn-add-record"
            >
              Add new record
            </button>
          </div>
          {/* {records.length === 0 ? null : 
          
        } */}
          <RecordsTable records={records} onRecordDeleted={fetchRecords} />
        </div>
      </div>
      <ModalAddRecord
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onFileAdded={fetchRecords}
      />
      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};

export default Records;
