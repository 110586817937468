import React, { useEffect, useState } from "react";
import "../custom-css/Login.css";
import { recommendationsClient, supplementsClients } from "../api/clients_api";
import { getSessionClient } from "../api/api_urls";
import "../custom-css/ClientRecommendationPage.css";

import { useParams, useNavigate } from "react-router-dom";

const ClientRecommendationPage = () => {
  const [recommendations, setRecommendations] = useState([]);
  const [supplements, setSupplements] = useState([]);
  const { consultationId, session } = useParams();
  const history = useNavigate();

  const sessionId = getSessionClient();

  useEffect(() => {
    getRecommendations();
    getSupplements();
  }, []);

  const goBack = () => {
    history(-1);
  };

  const getRecommendations = async () => {
    try {
      const response = await recommendationsClient(sessionId, consultationId);
      if (response.success) {
        setRecommendations(response.data);
      } else {
        alert("There has been an error");
      }
    } catch (error) {
      console.error("ClientRecommendationPage error:", error.message);
      alert("An error occurred while logging in.");
    }
  };

  const getSupplements = async () => {
    try {
      const response = await supplementsClients(sessionId, consultationId);
      if (response.success) {
        setSupplements(response.data);
        console.log(supplements.supplements);
      } else {
        alert("There has been an error");
      }
    } catch (error) {
      console.error("ClientRecommendationPage error:", error.message);
      alert("An error occurred while logging in.");
    }
  };

  return (
    <div className="client-recommendation-page-container">
      <div className="recommendation-appbar">
        <i class="fa-solid fa-arrow-left" onClick={goBack}></i>
      </div>
      <div className="recommendation-section">
        <div>
          <div className="recommendation-title-container">
            <h2>
              <i class="fa-solid fa-notes-medical"></i>Recommendations
            </h2>
          </div>
          {recommendations.map((recommendation, index) => (
            <div key={index} className="recommendation-container">
              <h3>
                <i class="fa-solid fa-caret-right"></i>
                {recommendation.title}
              </h3>
              <div
                dangerouslySetInnerHTML={{ __html: recommendation.response }}
                className="recommendation-response"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="recommendation-section">
        <div className="recommendation-title-container">
          <h2>
            <i class="fa-solid fa-prescription-bottle"></i>Supplements
          </h2>
        </div>
        {supplements.length === 0
          ? null
          : supplements.supplements.map((supplement, index) => (
              <div key={index} className="recommendation-container">
                <h3>
                  <i class="fa-solid fa-caret-right"></i>
                  <span
                    onClick={() => window.open(supplement.link, "_blank")}
                    className="supplement-name"
                  >
                    {supplement.name}
                  </span>
                </h3>
                <div className="supplement-spec">
                  <div>
                    <img
                      src={supplement.imageUrl}
                      className="supplement-img"
                    ></img>
                  </div>
                  <div className="supp-spec-details">
                    <p>
                      <span>Dose:</span> {supplement.dose}
                    </p>
                    <p>
                      <span>Morning:</span> {supplement.morning}
                    </p>
                    <p>
                      <span>Day:</span> {supplement.day}
                    </p>
                    <p>
                      <span>Evening:</span> {supplement.evening}
                    </p>
                  </div>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default ClientRecommendationPage;
