import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./Sidebar";
import Dashboard from "./Dashboard";
import Users from "./Users";
import Consultations from "./Consultations";
import Records from "./Records/Records";
import Login from "./Login";
import UserDetails from "./UsersFolder/UserDetails";
import "./custom-css/App.css";
import ConsultationDetails from "./Consultations/ConsultationDetails/ConsultationDetails";
import RecordDetails from "./Records/RecordDetails";
import PackagePage from "./UsersFolder/Package/PackagePage";
import Leads from "./Leads/LeadsPage";
import LeadDetails from "./Leads/LeadsDetails/LeadsDetailsPage";
import DiscoveryCallsPage from "./DiscoveryCalls/DiscoveryCallsPage";
import SupplementsPage from "./Supplements/SupplementsPage";
import ClientPageLogin from "./ClientPage/ClientPageLogin";
import ClientMainPage from "./ClientPage/ClientMainPage";
import ClientRecommendationPage from "./ClientPage/ClientRecommendationPage";
import SupplementsTable from "./Supplements/SupplementsTable";
import SupplementDetailsPage from "./Supplements/SupplementDetails/SupplementDetailsPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/clients/login" element={<ClientPageLogin />} />
        <Route path="/clients/:id" element={<ClientMainPage />} />
        <Route
          path="/recommendation/:consultationId/:session"
          element={<ClientRecommendationPage />}
        />
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard/*"
          element={
            <div className="container">
              <Sidebar />
              <Dashboard />
            </div>
          }
        />
        <Route
          path="/users"
          element={
            <div className="container">
              <Sidebar />
              <Users />
            </div>
          }
        />
        <Route
          path="/consultations"
          element={
            <div className="container">
              <Sidebar />
              <Consultations />
            </div>
          }
        />
        <Route
          path="/records"
          element={
            <div className="container">
              <Sidebar />
              <Records />
            </div>
          }
        />
        <Route
          path="/leads"
          element={
            <div className="container">
              <Sidebar />
              <Leads />
            </div>
          }
        />
        <Route
          path="/discovery-calls"
          element={
            <div className="container">
              <Sidebar />
              <DiscoveryCallsPage />
            </div>
          }
        />
        <Route
          path="/supplements"
          element={
            <div className="container">
              <Sidebar />
              <SupplementsPage />
            </div>
          }
        />
        <Route
          path="/leads/details/:id"
          element={
            <div className="container">
              <Sidebar />
              <LeadDetails />
            </div>
          }
        />
        <Route
          path="/user/details/:id"
          element={
            <div className="container">
              <Sidebar />
              <UserDetails />
            </div>
          }
        />
        <Route
          path="/consultation/details/:id"
          element={
            <div className="container">
              <Sidebar />
              <ConsultationDetails />
            </div>
          }
        />
        <Route
          path="/record/details/:id"
          element={
            <div className="container">
              <Sidebar />
              <RecordDetails />
            </div>
          }
        />
        <Route
          path="/user/package/:id"
          element={
            <div className="container">
              <Sidebar />
              <PackagePage />
            </div>
          }
        />
        <Route
          path="/supplements/details/:id"
          element={
            <div className="container">
              <Sidebar />
              <SupplementDetailsPage />
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
