// UserDetails.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TopRow from "../../TopRow/TopRow";
import "../../custom-css/UserDetails.css";
import "../../custom-css/App.css";
import { getLeadDetails } from "../../api/get_lead_details";
import ProfileTable from "../../UsersFolder/UserDetails/ProfileTable";
import ErrorModal from "../../GeneralModal/ErrorSessionModal";
import DiscoveryCallLead from "./DiscoveryCall";
import "../../custom-css/LeadsDetailsPage.css";
import LeadsNotesTable from "./LeadsNotesTable";

const LeadDetails = () => {
  const { id } = useParams();
  const [leadDetails, setLeadDetails] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const fetchData = async () => {
    try {
      const response = await getLeadDetails(id); // Fetch client data
      if (response.success) {
        // Assuming the fetched data is in response.data
        setLeadDetails(response.data);
      } else {
        // Handle fetch failure
        console.error("Fetching client data failed:", response.error);
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error("Error during client data fetching:", error);
      setShowErrorModal(true);
      // Handle error
    }
  };
  useEffect(() => {
    fetchData(); // Call the fetchData function to fetch client data
  }, [id]);

  return (
    <div className="user-details-main-container">
      <div className="navbar">
        <TopRow adminName={"Admin"} />
      </div>
      <div className="user-details-internal-container">
        <h2>
          Leads / {leadDetails?.name}{" "}
          {leadDetails?.converted ? (
            <span>
              <i class="fa-solid fa-award"></i>Converted
            </span>
          ) : null}{" "}
        </h2>
        <div className="row-tables-flex">
          <LeadsNotesTable leadId={parseInt(id)} />
          {leadDetails?.converted ? null : (
            <div className="lead-details-column">
              <DiscoveryCallLead leadId={id} />
              <ProfileTable
                userDetails={leadDetails}
                isClient={false}
                onUpdateClient={fetchData}
              />
            </div>
          )}
        </div>
      </div>

      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};

export default LeadDetails;
