// AddClientModal.js
import React, { useEffect, useState } from "react";
import { insertClient } from "../../api/insert_clients";

import "../../custom-css/AddClientModal.css";
import "../../custom-css/App.css";

const AddClientModal = ({ isOpen, onClose, onSuccess, onClientAdded }) => {
  const initialFormData = {
    email_address: "",
    phone_number: "",
    name: "",
    age: null,
    city: "",
    telegram_id: "",
    language: 2,
    converted_lead: false,
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    try {
      if (
        formData.phone_number.trim() === "" &&
        formData.telegram_id.trim() === ""
      ) {
        alert("Phone Number or Telegram ID is required.");
        return;
      }

      const response = await insertClient(formData);

      if (response.success) {
        // Check the status code for success (e.g., 200 or 201)
        if (response.statusCode === 200 || response.statusCode === 201) {
          // Call the onSuccess function when the API call is successful
          onSuccess(response.data);
          onClose();
          resetForm(); // Close the modal
          if (onClientAdded) {
            onClientAdded();
          }
        } else {
          alert("Error: " + response); // Handle other success status codes if needed
        }
      } else {
        console.log(response);
        alert("Error: " + response.error); // Handle non-successful response
      }
    } catch (error) {
      console.error("API error:", error.message);
      alert("An error occurred while inserting the client.");
    }
  };
  const resetForm = () => {
    setFormData(initialFormData);
  };

  const closeModal = () => {
    resetForm();
    onClose();
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal add-client-modal">
        <div className="modal-content">
          <h2>
            <i class="fa-solid fa-user-plus"></i>Add New Client
          </h2>
          <div>
            <input
              type="text"
              name="email_address"
              placeholder="Email Address"
              value={formData.email_address}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              name="phone_number"
              placeholder="Phone Number"
              value={formData.phone_number}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              name="age"
              placeholder="Age"
              value={formData.age}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              name="city"
              placeholder="City"
              value={formData.city}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              name="telegram_id"
              placeholder="Telegram ID"
              value={formData.telegram_id}
              onChange={handleChange}
            />
          </div>
          <div className="select-container">
            <label>Language</label>
            <select
              name="language"
              value={formData.language}
              onChange={handleChange}
              className="add-package-select"
            >
              <option value={2}>Russian</option>
              <option value={1}>English</option>
            </select>
          </div>
          <div className="modal-buttons">
            <button onClick={closeModal} className="button-12">
              Cancel
            </button>
            <button onClick={handleSave} className="button-10">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default AddClientModal;
