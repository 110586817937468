// AddClientModal.js
import React, { useEffect, useState } from "react";

import "../../custom-css/AddClientModal.css";
import "../../custom-css/App.css";
import { updateClientProfile } from "../../api/insert_clients";
import { updateLead } from "../../api/insert_lead";

const ModifyProfile = ({
  isOpen,
  onClose,
  onUpdateDone,
  initialFormData,
  type,
}) => {
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    // Update the form data when initialFormData changes
    setFormData(initialFormData);
  }, [initialFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    try {
      let response;
      if (type === "client") {
        response = await updateClientProfile(formData, initialFormData.id);
      } else {
        response = await updateLead(formData, initialFormData.id);
      }
      if (response.success) {
        // Check the status code for success (e.g., 200 or 201)
        if (response.statusCode === 200 || response.statusCode === 201) {
          // Call the onSuccess function when the API call is successful
          alert("Client profile updated correctly!");
          onClose(); // Close the modal
          if (onUpdateDone) {
            onUpdateDone();
          }
        } else {
          alert("Error: " + response); // Handle other success status codes if needed
        }
      } else {
        alert("Error: " + response.error); // Handle non-successful response
      }
    } catch (error) {
      console.error("API error:", error.message);
      alert("An error occurred while inserting the client.");
    }
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal add-client-modal">
        <div className="modal-content">
          <h2>
            <i class="fa-solid fa-user-pen"></i>Edit{" "}
            {type === "client" ? <span>Client</span> : <span>Lead</span>}
          </h2>
          <div className="input-edit-profile">
            <label>Name</label>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="input-edit-profile">
            <label>Email Address</label>
            <input
              type="text"
              name="email_address"
              placeholder="Email Address"
              value={formData.email_address}
              onChange={handleChange}
            />
          </div>
          <div className="input-edit-profile">
            <label>Phone number</label>
            <input
              type="text"
              name="phone_number"
              placeholder="Phone Number"
              value={formData.phone_number}
              onChange={handleChange}
            />
          </div>
          <div className="input-edit-profile">
            <label>Age</label>
            <input
              type="text"
              name="age"
              placeholder="Age"
              value={formData.age}
              onChange={handleChange}
            />
          </div>
          <div className="input-edit-profile">
            <label>City</label>
            <input
              type="text"
              name="city"
              placeholder="City"
              value={formData.city}
              onChange={handleChange}
            />
          </div>
          <div className="input-edit-profile">
            <label>Telegram Id</label>
            <input
              type="text"
              name="telegram_id"
              placeholder="Telegram ID"
              value={formData.telegram_id}
              onChange={handleChange}
            />
          </div>
          <div className="select-container">
            <label>Language</label>
            <select
              name="language"
              value={formData.language}
              onChange={handleChange}
              className="add-package-select"
            >
              <option value={2}>Russian</option>
              <option value={1}>English</option>
            </select>
          </div>
          <div className="modal-buttons">
            <button onClick={onClose} className="button-12">
              Cancel
            </button>
            <button onClick={handleSave} className="button-10">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ModifyProfile;
