// AddClientModal.js
import React, { useEffect, useState } from "react";

import "../../custom-css/AddClientModal.css";
import "../../custom-css/App.css";
import "../../custom-css/ModalNewSupplementList.css";
import { deleteSupplementList } from "../../api/supplements";

const DeleteSupplementModal = ({
  supplement,
  isOpen2,
  onClose2,
  onDeletedSupp,
}) => {
  const deleteSupp = async () => {
    try {
      const response = await deleteSupplementList(supplement.id);

      if (response.success) {
        alert("Supplement deleted");
        onClose2();
        if (onDeletedSupp) {
          onDeletedSupp();
        }
      } else {
        console.log(response);
        alert("Error: " + response.error); // Handle non-successful response
      }
    } catch (error) {
      console.error("API error:", error.message);
      alert("An error occurred while inserting the client.");
    }
  };

  return isOpen2 ? (
    <div className="modal-overlay">
      <div className="modal add-client-modal">
        <div className="modal-content new-supplement-container">
          <h2>
            <i class="fa-solid fa-capsules"></i>Delete supplement
          </h2>
          <h4>
            Are you sure you want to delete the supplement {supplement.name}?
          </h4>
          {supplement.use ? (
            <div className="alert-div-use-supp">
              <i class="fa-solid fa-triangle-exclamation "></i>
              <p>
                The supplement has been prescribed to one or more clients
                already. If you delete it, the client will not see it anymore
                and it can cause issues.
              </p>
            </div>
          ) : null}

          <div className="modal-buttons">
            <button onClick={onClose2} className="button-12">
              Cancel
            </button>
            <button onClick={deleteSupp} className="button-10">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default DeleteSupplementModal;
