import {
  DELETE_LEAD_NOTE_URL,
  DELETE_NOTE_CLIENT_URL,
  GET_ALL_NOTES_CLIENT_URL,
  GET_LEAD_NOTES_URL,
  INSERT_LEAD_NOTE_URL,
} from "./api_urls";
import { INSERT_NOTE_CLIENT_URL } from "./api_urls";

import { getAdminId, getSessionId } from "./api_urls";

// Function to perform login API call
export const getNotesByClientId = async (clientId) => {
  const apiUrl = GET_ALL_NOTES_CLIENT_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "client-id": clientId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const insertNoteClient = async (clientId, text) => {
  const apiUrl = INSERT_NOTE_CLIENT_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "client-id": clientId,
    },
    body: JSON.stringify({ textNote: text }),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const deleteNoteById = async (noteId) => {
  const apiUrl = DELETE_NOTE_CLIENT_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "note-id": noteId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const getLeadNotesById = async (leadId) => {
  const apiUrl = GET_LEAD_NOTES_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "lead-id": leadId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const insertLeadNote = async (leadId, text) => {
  const apiUrl = INSERT_LEAD_NOTE_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "lead-id": leadId,
    },
    body: JSON.stringify({ textNote: text }),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const deleteLeadNote = async (noteId) => {
  const apiUrl = DELETE_LEAD_NOTE_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "note-id": noteId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};
