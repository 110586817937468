// UserDetails.js
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import TopRow from "../TopRow/TopRow";
import ProfileTable from "./UserDetails/ProfileTable";
import NotesTable from "./UserDetails/NotesTable";
import { getClientData } from "../api/get_client_data";
import "../custom-css/UserDetails.css";
import PackagesTable from "./UserDetails/PackagesTable";
import ConsultationsTable from "./UserDetails/ConsultationsClientTable";
import { getConsultationsByClient } from "../api/get_consultations_client";
import ErrorModal from "../GeneralModal/ErrorSessionModal";
import LoginTable from "./UserDetails/LoginTable";
import RecordsTable from "./UserDetails/RecordsTable";
import { domain } from "../api/api_urls";
import IntakeForm from "./UserDetails/IntakeForm";

const UserDetails = ({}) => {
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [consultations, setConsultations] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const navigate = useNavigate();

  const fetchConsultations = async () => {
    try {
      const response = await getConsultationsByClient(id); // Fetch client data
      if (response.success) {
        // Assuming the fetched data is in response.data as an array of objects
        setConsultations(response.data);
      } else {
        // Handle fetch failure
        console.error("Fetching client data failed:", response.error);
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error("Error during client data fetching:", error);
      setShowErrorModal(true);

      // Handle error
    }
  };
  const fetchData = async () => {
    try {
      const response = await getClientData(id); // Fetch client data
      if (response.success) {
        // Assuming the fetched data is in response.data
        setUserDetails(response.data);
      } else {
        // Handle fetch failure
        console.error("Fetching client data failed:", response.error);
      }
    } catch (error) {
      console.error("Error during client data fetching:", error);
      // Handle error
    }
  };

  const navigateToLead = () => {
    window.open(
      `https://crm.elenanutritionist.com/leads/details/${userDetails?.lead_id}`,
      "_blank"
    );
  };

  useEffect(() => {
    fetchData();
    fetchConsultations();
  }, [id]);

  const updateConsultationsTable = () => {
    fetchConsultations();
  };

  return (
    <div className="user-details-main-container">
      <div className="navbar">
        <TopRow adminName={"Admin"} />
      </div>
      <div className="user-details-internal-container">
        <h2>Clients / {userDetails?.name} </h2>
        <div className="user-details-first-row">
          <div className="user-details-first-column">
            <NotesTable userId={parseInt(id)} />
            {userDetails ? (
              <IntakeForm userDetails={userDetails} updateClient={fetchData} />
            ) : null}
          </div>
          <div className="column-tables">
            <LoginTable />
            <ProfileTable
              userDetails={userDetails}
              isClient={true}
              onUpdateClient={() => fetchData()}
            />
            <RecordsTable />
            <PackagesTable
              updateConsultationsTable={updateConsultationsTable}
            />
            <ConsultationsTable consultations={consultations} />
          </div>
        </div>
        <div className="user-details-second-row"></div>
      </div>
      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};

export default UserDetails;
