// api/auth.js
import {
  GET_RECOMMENDATION_RESPONSES_URL,
  GET_RECOMMENDATION_URL,
  INSERT_RECOMMENDATION_URL,
  TEST_URL,
} from "./api_urls";

import { getAdminId, getSessionId } from "./api_urls";

// Function to perform login API call
export const getRecommendation = async (id) => {
  const apiUrl = GET_RECOMMENDATION_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "language-id": id,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const getRecommendationResponse = async (id, consultationId) => {
  const apiUrl = GET_RECOMMENDATION_RESPONSES_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "client-id": id,
      "consultation-id": consultationId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const insertRecommendation = async (requestBody) => {
  const apiUrl = INSERT_RECOMMENDATION_URL; // Replace with your API endpoint URL
  const adminId = getAdminId(); // Implement or replace with the function to get the admin ID
  const sessionId = getSessionId(); // Implement or replace with the function to get the session ID
  const requestOptions = {
    method: "POST", // Use POST method to send data
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
    },
    body: JSON.stringify(requestBody),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();
      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during insertSectionA:", error);
    throw new Error("An error occurred while inserting Section A data.");
  }
};

export const testRecomend = async () => {
  const apiUrl = TEST_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "consultation-id": 50,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};
