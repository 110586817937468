// AddClientModal.js
import React, { useState } from "react";

import "../../custom-css/AddClientModal.css";
import "../../custom-css/App.css";
import {
  deleteLeadNote,
  deleteNoteById,
  insertNoteClient,
} from "../../api/notes";

const DeleteNoteModal = ({ isOpen, onClose, noteId, onNoteDeleted, type }) => {
  const handleDelete = async () => {
    try {
      if (type === "client") {
        await deleteNoteById(noteId);
      } else {
        await deleteLeadNote(noteId);
      }
      // Call your API delete function here passing the noteId
      // Call the success callback passed as a prop
      onClose(); // Close the modal

      if (onNoteDeleted) {
        onNoteDeleted();
      }
    } catch (error) {
      console.error("Error deleting note:", error);
      // Handle error
    }
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal add-client-modal">
        <div className="modal-content">
          <h2>
            <i class="fa-solid fa-circle-plus"></i>Delete Note
          </h2>
          <div>
            <p>Are you sure you want to delete the note?</p>
          </div>
          <div className="modal-buttons">
            <button onClick={onClose} className="button-12">
              Cancel
            </button>
            <button onClick={handleDelete} className="button-10">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default DeleteNoteModal;
