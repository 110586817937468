import React, { useState, useEffect } from "react";
import MyCalendar from "./Dashboard/Calendar";
import "./custom-css/Dashboard.css";
import TopRow from "./TopRow/TopRow";
import { DayWidgetDashboard } from "./Dashboard/DayWidget";
import { getAppointmentsAll } from "./api/get_appointments";
import ErrorModal from "./GeneralModal/ErrorSessionModal";
import { useLocation } from "react-router-dom";

const Dashboard = () => {
  const [selectedDay, setSelectedDay] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const today = new Date();

  const handleDaySelect = (day) => {
    setSelectedDay(day);
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    try {
      const { success, data } = await getAppointmentsAll();
      if (success) {
        setAppointments(data);
      } else {
        // Handle error
        console.error("Failed to fetch appointments:", data);
        setShowErrorModal(true);
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching appointments:", error);
      setShowErrorModal(true);
    }
  };

  return (
    <div className="dashboard">
      <TopRow adminName={"admin"} />
      <div className="dashboard-container">
        <h2 className="h2-dashboard">Dashboard</h2>
        <div className="dashboard-first-row">
          <MyCalendar onSelect={handleDaySelect} appointments={appointments} />
          <DayWidgetDashboard
            day={selectedDay ? selectedDay : today}
            appointments={appointments}
          />
        </div>
      </div>
      <div className="error-modal-container">
        <ErrorModal
          show={showErrorModal}
          onClose={() => setShowErrorModal(false)}
        />
      </div>
    </div>
  );
};

export default Dashboard;
