import React, { useState } from "react";

import "../../custom-css/ConsultationDetails.css";
import { formatDateTime } from "../../functions/date_format";
import ModalSetDateConsultation from "./ModalSetDateConsultation";
import ModalChangeStatusConsultation from "./ModalChangeStatus";
import ModalSetFollowUp from "./ModalSetFollowUp";

const ConsultationDetailsTable = ({
  consultationDetails,
  updateConsultation,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenStatus, setIsModalOpenStatus] = useState(false);
  const [isModalOpenFollowUp, setIsModalOpenFollowUp] = useState(false);

  const renderDate = () => {
    if (consultationDetails?.date === null) {
      return (
        <td className="table-cell">
          <button onClick={handleSetDate} className="button-10">
            Choose date
          </button>
        </td>
      );
    }

    return (
      <td className="table-cell select-date-cell">
        {formatDateTime(consultationDetails?.date)}
        <button onClick={handleSetDate} className="button-10">
          <i class="fa-solid fa-pen-to-square"></i>
        </button>
      </td>
    );
  };

  // Function to handle adding a new note
  const handleSetDate = () => {
    setIsModalOpen(true);
  };
  const handleSetStatus = () => {
    setIsModalOpenStatus(true);
  };
  const handleSetFollowUp = () => {
    setIsModalOpenFollowUp(true);
  };

  // Function to handle closing the modal
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleModalCloseStatus = () => {
    setIsModalOpenStatus(false);
  };
  const handleModalCloseFollowUp = () => {
    setIsModalOpenFollowUp(false);
  };

  return (
    <div className="consultation-details-table-container">
      <div className="title-table-notes">
        <h3>Details</h3>
      </div>
      <table className="consultation-details-table">
        <tbody>
          <tr>
            <td className=" cell-header profile-table-row-title">ID:</td>
            <td className="table-cell">{consultationDetails?.id}</td>
          </tr>
          <tr>
            <td className="cell-header profile-table-row-title">
              Date Consultation:
            </td>
            {renderDate()}
          </tr>
          <tr>
            <td className="cell-header profile-table-row-title">Package:</td>
            <td className="table-cell">{consultationDetails?.package_name}</td>
          </tr>
          <tr>
            <td className="cell-header profile-table-row-title ">Status:</td>
            <td className="table-cell select-date-cell">
              {consultationDetails?.status}
              <button onClick={handleSetStatus} className="button-10">
                <i class="fa-solid fa-pen-to-square"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td className="cell-header profile-table-row-title">
              Is Follow Up:
            </td>
            <td className="table-cell select-date-cell">
              {consultationDetails?.order === 1 ? "No" : "Yes"}
              <button onClick={handleSetFollowUp} className="button-10">
                Change
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <ModalSetDateConsultation
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onDateSet={updateConsultation}
        consultation_id={consultationDetails?.id}
      />
      <ModalChangeStatusConsultation
        isOpen={isModalOpenStatus}
        onClose={handleModalCloseStatus}
        onChangeStatusDone={updateConsultation}
        consultation_id={consultationDetails?.id}
      />
      <ModalSetFollowUp
        isOpen={isModalOpenFollowUp}
        onClose={handleModalCloseFollowUp}
        onChangeStatusDone={updateConsultation}
        consultationDetails={consultationDetails}
      />
    </div>
  );
};

export default ConsultationDetailsTable;
