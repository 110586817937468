// api.js
import {  INSERT_PACKAGE_URL, SET_PAID_PACKAGE_URL } from "./api_urls"
import { getAdminId, getSessionId } from "./api_urls";

export const insertPackage = async (clientId, packageData) => {
  const apiUrl = INSERT_PACKAGE_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'admin-id': adminId,
      'session-id': sessionId,
      'client-id': clientId
    },
    body: JSON.stringify(packageData),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    const statusCode = response.status;

    // Parse the response data if it's a successful status code
    if (response.ok) {
      const data = await response.json();

      return { success: true, data, statusCode };
    } else {
      const error = await response.json();

      // Handle non-successful status codes here
      return { success: false, error };
    }
  } catch (error) {

    throw new Error("An error occurred while inserting the client.");
  }
};


export const setPaid = async (packageId, paymentMethod) => {
  const apiUrl = SET_PAID_PACKAGE_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'admin-id': adminId,
      'session-id': sessionId,
      'package-id': packageId
    },
    body:JSON.stringify( {payment_method : paymentMethod}),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    const statusCode = response.status;

    // Parse the response data if it's a successful status code
    if (response.ok) {
      const data = await response.json();

      return { success: true, data, statusCode };
    } else {
      const error = await response.json();

      // Handle non-successful status codes here
      return { success: false, error };
    }
  } catch (error) {

    throw new Error("An error occurred while inserting the client.");
  }
};
