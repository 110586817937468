import React, { useEffect, useState } from "react";
import TopRow from "./TopRow/TopRow";
import ConsultationsTable from "./Consultations/ConsultationsTable";
import { getConsultations } from "./api/get_consultations";
import ErrorModal from "./GeneralModal/ErrorSessionModal";

const Consultations = () => {
  const [consultations, setConsultations] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const fetchData = async () => {
    try {
      const response = await getConsultations(); // Fetch client data
      if (response.success) {
        // Assuming the fetched data is in response.data
        setConsultations(response.data);
      } else {
        // Handle fetch failure

        setShowErrorModal(true);

        console.error("Fetching client data failed:", response.error);
      }
    } catch (error) {

      setShowErrorModal(true);
      console.error("Error during client data fetching:", error);
      // setShowErrorModal(true);
      // Handle error
    }
  };
  useEffect(() => {
    fetchData(); // Call the fetchData function to fetch client data
  }, []);

  return (
    <div className="users-scrollable-container">
      <div className="users">
        <div className="navbar">
          <TopRow adminName={"Admin"} />
        </div>
        <div className="users-container">
          <div className="users-header">
            <h2>Consultations</h2>
          </div>
          {consultations.length === 0 ? (
            <p>No consultations</p>
          ) : (
            <ConsultationsTable consultations={consultations} />
          )}
        </div>
      </div>
      <div className="error-modal-container">
        <ErrorModal
          show={showErrorModal}
          onClose={() => setShowErrorModal(false)}
        />
      </div>
    </div>
  );
};

export default Consultations;
