import {
  SET_DATE_CONSULTATION_URL,
  SET_FOLLOW_UP_URL,
  SET_STATUS_CONSULTATION_URL,
} from "./api_urls";
import { getAdminId, getSessionId } from "./api_urls";

export const setDateConsultation = async (consultationID, dateNew) => {
  const apiUrl = SET_DATE_CONSULTATION_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "consultation-id": consultationID,
    },
    body: JSON.stringify({ new_date: dateNew }),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    const statusCode = response.status;

    // Parse the response data if it's a successful status code
    if (response.ok) {
      const data = await response.json();

      return { success: true, data, statusCode };
    } else {
      const error = await response.json();

      // Handle non-successful status codes here
      return { success: false, error };
    }
  } catch (error) {
    throw new Error("An error occurred while inserting the client.");
  }
};

export const changeStatusConsultation = async (consultationID, statusNew) => {
  const apiUrl = SET_STATUS_CONSULTATION_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "consultation-id": consultationID,
    },
    body: JSON.stringify({ new_status: statusNew }),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    const statusCode = response.status;

    // Parse the response data if it's a successful status code
    if (response.ok) {
      const data = await response.json();

      return { success: true, data, statusCode };
    } else {
      const error = await response.json();

      // Handle non-successful status codes here
      return { success: false, error };
    }
  } catch (error) {
    throw new Error("An error occurred while inserting the client.");
  }
};

export const changeFollowUpConsultation = async (
  consultationID,
  orderNumber
) => {
  const apiUrl = SET_FOLLOW_UP_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "consultation-id": consultationID,
    },
    body: JSON.stringify({ order_number: orderNumber }),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    const statusCode = response.status;

    // Parse the response data if it's a successful status code
    if (response.ok) {
      const data = await response.json();

      return { success: true, data, statusCode };
    } else {
      const error = await response.json();

      // Handle non-successful status codes here
      return { success: false, error };
    }
  } catch (error) {
    throw new Error("An error occurred while inserting the client.");
  }
};
