import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import "../../custom-css/ProfileTable.css";
import { formatDate } from "../../functions/date_format";
import { getPackagesClient } from "../../api/get_packages_client";
import AddPackageModal from "../Package/Modals/AddPackageModal";
import TitleTablesExpandable from "./TitleTablesExpandable";
import ErrorModal from "../../GeneralModal/ErrorSessionModal";
import { getRecordByClient } from "../../api/records";
import ModalAddRecord from "../../Records/Modal/ModalAddRecord";

const RecordsTable = ({}) => {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [records, setRecords] = useState([]);
  const [expand, setExpand] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const fetchData = async () => {
    try {
      const response = await getRecordByClient(id); // Fetch client data
      if (response.success) {
        // Assuming the fetched data is in response.data as an array of objects
        setRecords(response.data);
      } else {
        // Handle fetch failure
        console.error("Fetching client data failed:", response.error);
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error("Error during client data fetching:", error);
      setShowErrorModal(true);
      // Handle error
    }
  };

  useEffect(() => {
    fetchData(); // Call the fetchData function to fetch client data
  }, [id]);

  const handleAddRecord = () => {
    // Open the modal when the "Add New Client" button is clicked
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    // Close the modal
    setIsModalOpen(false);
  };

  const handlePackageAdded = () => {
    fetchData();
  };

  const handleExpand = () => {
    setExpand(!expand);
  };

  return (
    <div className="profile-table-container">
      <TitleTablesExpandable
        title={"Records"}
        buttonText={"Add new record"}
        onClick={handleAddRecord}
        isExpanded={expand}
        expandTable={handleExpand}
      />
      {expand && records.length === 0 ? (
        <p>No records yet</p>
      ) : expand ? (
        <table className="profile-table">
          <tbody>
            <tr>
              <td className="notes-table-column-title">ID</td>
              <td className="notes-table-column-title">Type</td>
              <td className="notes-table-column-title">Date</td>
              <td className="notes-table-column-title"></td>
            </tr>
            {records.map((item) => (
              <tr key={item.id}>
                <td className="table-cell">
                  <Link to={`/record/details/${item.id}`} className="user-link">
                    {item.id}
                  </Link>
                </td>
                <td className="table-cell">{item.type}</td>
                <td className="table-cell">{formatDate(item.date)}</td>
                <td className="table-cell">
                  <button
                    onClick={() => window.open(item.link, "_blank")}
                    className="button-10"
                  >
                    <i class="fa-solid fa-eye"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}

      <ModalAddRecord
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onFileAdded={fetchData}
        clientId={id}
      />
      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};

export default RecordsTable;
