// AddClientModal.js
import React, { useEffect, useState } from "react";

import "../../../custom-css/AddClientModal.css";
import "../../../custom-css/App.css";
import { editSupplementClient } from "../../../api/supplements";

const ModalEditSupplementClient = ({
  isOpen,
  onClose,
  onEditDone,
  initialFormData,
  supplementId,
  consultationId,
}) => {
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    // Update the form data when initialFormData changes
    console.log(initialFormData);
    setFormData(initialFormData);
  }, [initialFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEdit = async () => {
    try {
      const response = await editSupplementClient(
        supplementId,
        consultationId,
        formData.dose,
        formData.morning,
        formData.day,
        formData.evening,
        formData.notes_instructions
      );
      if (response.success) {
        onClose(); // Close the modal
        if (onEditDone) {
          onEditDone();
        }
        alert("Supplement updated correctly!");
      } else {
        alert("Error OR HERE: " + response.error); // Handle non-successful response
      }
    } catch (error) {
      console.error("API error:", error.message);
      alert("An error occurred while inserting the client.");
    }
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal add-client-modal">
        <div className="modal-content">
          <h2>
            <i class="fa-solid fa-capsules"></i>Edit Supplement {formData.name}
          </h2>

          <div className="input-edit-profile">
            <label>Dose</label>
            <input
              type="text"
              onChange={handleChange}
              placeholder="Dose"
              name="dose"
              value={formData.dose}
            />
          </div>
          <div className="input-edit-profile">
            <label>Morning</label>
            <input
              type="text"
              name="morning"
              placeholder="Morning"
              value={formData.morning}
              onChange={handleChange}
            />
          </div>
          <div className="input-edit-profile">
            <label>Day</label>
            <input
              type="text"
              name="day"
              placeholder="Day"
              value={formData.day}
              onChange={handleChange}
            />
          </div>
          <div className="input-edit-profile">
            <label>Evening</label>
            <input
              type="text"
              name="evening"
              placeholder="Evening"
              value={formData.evening}
              onChange={handleChange}
            />
          </div>
          <div className="input-edit-profile">
            <label>Notes Instructions</label>
            <input
              type="text"
              name="notes_instructions"
              placeholder="Notes/Instructions"
              value={formData.notes_instructions}
              onChange={handleChange}
            />
          </div>
          <div className="modal-buttons">
            <button onClick={onClose} className="button-12">
              Cancel
            </button>
            <button onClick={handleEdit} className="button-10">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ModalEditSupplementClient;
