// AddClientModal.js
import React, { useEffect, useState } from "react";

import "../../../custom-css/AddClientModal.css";
import "../../../custom-css/App.css";
import "../../../custom-css/ModalNewSupplementList.css";
import "../../../custom-css/ModalPreviousSupplements.css";
import { getSupplementsPreviousConsultation, insertNewSupplement, insertSupplementClient } from "../../../api/supplements";

const ModalPreviousSupplements = ({ isOpenModalPrevious, onCloseModalPrevious, onPreviousAdded, clientId, consultationId }) => {

    const [oldSupplements, setOldSupplements] = useState([]);


    useEffect(() => {
        getSupplementsOld();

    }, []);

    const getSupplementsOld = async () => {
        try {

            const response = await getSupplementsPreviousConsultation(clientId, consultationId);
            console.log("response", response.statusCode);
            if (response.success) {
                setOldSupplements(response.data);
            } else {
                console.log(response);
                alert("Error: " + response.error); // Handle non-successful response
            }
        } catch (error) {
            console.error("API error:", error.message);
            alert("An error occurred while inserting the client.");
        }
    }

    const handleSaveSupplement = async (clientId, consultationId, supplement) => {
        try {
            const response = await insertSupplementClient(
                clientId,
                consultationId,
                supplement.dose,

                supplement.morning, supplement.day, supplement.evening, supplement.notes, supplement.supplement_id




            );
            if (response.success) {
                // Assuming the fetched data is in response.data
                alert("Supplement added");
                onPreviousAdded();
                // setSupplements(response.data);

            } else {
                // Handle fetch failureshowErrorModal("An error occurred while fetching data.");

                console.error("Fetching clients failed:", response.error);
                alert("Generic error");
            }
        } catch (error) {
            console.error("Error during client data fetching:", error);
            // setShowErrorModal(true);
            alert("Generic error");
            // Handle error
        }
    };

    return isOpenModalPrevious ? (
        <div className="modal-overlay">
            <div className="modal add-client-modal modal-previous-supplements">
                <div className="modal-content new-supplement-container">
                    <h2>
                        <i class="fa-solid fa-capsules"></i>Supplements from previous consultations
                    </h2>
                    {oldSupplements
                        ? oldSupplements.map((supplement) => (
                            <div className="supplement-spec-div supplement-details-container">
                                <div className="img-container-supp">
                                    <img
                                        src={supplement.image_url}
                                        className="supplement-image"
                                    ></img>
                                </div>
                                <div className="supplement-title">
                                    <h4>{supplement.name}</h4>
                                    <a href={supplement.link} target="_blank">
                                        Link to shop
                                    </a>
                                    <div className="supp-details-container">
                                        <div className="supp-detail-line">
                                            Dose: <span>{supplement.dose}</span>
                                        </div>
                                        <div className="supp-detail-line">
                                            Morning: <span> {supplement.morning}</span>
                                        </div>
                                        <div className="supp-detail-line">
                                            Day: <span>{supplement.day}</span>
                                        </div>
                                        <div className="supp-detail-line">
                                            Evening: <span>{supplement.evening}</span>
                                        </div>
                                        <div className="supp-detail-line">
                                            Notes: <span> {supplement.notes_instructions}</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button
                                        onClick={() => handleSaveSupplement(clientId, consultationId, supplement)}
                                        className="button-10 btn-edit-supp"
                                    >
                                        Add
                                    </button>

                                </div>
                            </div>
                        ))
                        : null}

                    <div className="modal-buttons line-btn-previous">
                        <button onClick={onCloseModalPrevious} className="button-12">
                            Close
                        </button>

                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default ModalPreviousSupplements;
