// ErrorModal.js
import React, { useState } from "react";

import { login } from "../api/login";
import "../custom-css/ErrorModal.css";

const ErrorModal = ({ show }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  if (!show) return null;

  const handleLogin = async () => {
    try {
      const response = await login(username, password);

      if (response.success) {
        // Redirect to the dashboard on successful login
        const { admin_id, session_id } = response.data;
        localStorage.setItem("admin_id", admin_id);
        localStorage.setItem("session_id", session_id);
        window.location.reload();
        // localStorage.setItem("date-test", date);
      } else {
        alert("Invalid username or password");
      }
    } catch (error) {
      console.error("Login error:", error.message);
      alert("An error occurred while logging in.");
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal error-modal">
        <div className="modal-content error-modal-content">
          <h2>Session Expired</h2>
          <div>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="text"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="error-modal-btn-div">
            <button onClick={handleLogin} className="btn-login button-10">
              Enter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
