import React, { useEffect, useState } from "react";
import "./custom-css/Users.css";
// import usersData from "./json/users.json"; // Import the JSON data
import UserTable from "./UsersFolder/UserTable.js";
import TopRow from "./TopRow/TopRow.js";
import AddClientModal from "./UsersFolder/Modals/AddClientModal.js";
import SuccessModal from "./GeneralModal/SuccessModal.js";
import { getClients } from "./api/get_clients.js";
import TitleTables from "./GenericComponents/TitleTables";
import ErrorModal from "./GeneralModal/ErrorSessionModal";

const Users = () => {
  const adminName = "Admin Name";
  // const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    // Fetch client data when the component mounts
    handleClientAdded();
  }, []);

  const handleAddClient = () => {
    // Open the modal when the "Add New Client" button is clicked
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    // Close the modal
    setIsModalOpen(false);
  };

  const handleSuccessModalClose = () => {
    setIsSuccessModalOpen(false);
  };

  const handleSuccess = (newClientData) => {
    setIsSuccessModalOpen(true); // Open the success modal
    console.log("New client added:", newClientData);
  };
  const handleClientAdded = async () => {
    try {
      const response = await getClients();
      if (response.success) {
        // Assuming the fetched data is in response.data
        setUserData(response.data);
      } else {
        // Handle fetch failureshowErrorModal("An error occurred while fetching data.");
        setShowErrorModal(true);
        console.error("Fetching clients failed:", response.error);
      }
    } catch (error) {
      setShowErrorModal(true);
      console.error("Error during client data fetching:", error);
      // Handle error
    }
  };

  return (
    <div className="users-scrollable-container">
      <div className="users">
        <div className="navbar">
          <TopRow adminName={adminName} />
        </div>
        <div className="users-container">
          <div className="users-header">
            <TitleTables
              title={"Clients"}
              buttonText={"Add new client"}
              onClick={handleAddClient}
            />
            {/* <h2>Clients
              <button onClick={handleAddClient} className="button-10 add-client-button">
                Add New Client
              </button>
            </h2> */}
          </div>
          <UserTable onClientAdded={handleSuccess} userData={userData} />
          {/* {userData.length > 0 ? (
          ) : (
            <p>Loading...</p>
          )} */}
        </div>
      </div>
      <AddClientModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSuccess={handleSuccess}
        onClientAdded={handleClientAdded}
      />
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleSuccessModalClose}
        title={"Client added correctly"}
      />

      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};

export default Users;
