import {
  DELETE_RECORD_URL,
  GET_ALL_RECORDS_URL,
  GET_RECORD_BY_CLIENT_URL,
  GET_RECORD_BY_ID_URL,
  INSERT_RECORD_URL,
  UPLOAD_RECORD_URL,
} from "./api_urls";

import { getAdminId, getSessionId } from "./api_urls";

// Function to perform login API call
export const getAllRecords = async (id) => {
  const apiUrl = GET_ALL_RECORDS_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const getRecordByClient = async (clientId) => {
  const apiUrl = GET_RECORD_BY_CLIENT_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "client-id": clientId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const getRecordById = async (id) => {
  const apiUrl = GET_RECORD_BY_ID_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "record-id": id,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const uploadRecord = async (clientId, formData) => {
  const apiUrl = UPLOAD_RECORD_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "POST",
    headers: {
      //   "Content-Type": "multipart/form-data",
      "admin-id": adminId,
      "session-id": sessionId,
      "client-id": clientId,
    },
    body: formData,
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data: data, status: response.status };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData, status: response.status };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const insertRecord = async (clientId, type, filepath) => {
  const apiUrl = INSERT_RECORD_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
    },
    body: JSON.stringify({
      client_id: clientId,
      record_type: type,
      filepath: filepath,
    }),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data: data, status: response.status };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const deleteRecord = async (recordId) => {
  const apiUrl = DELETE_RECORD_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
    },
    body: JSON.stringify({
      record_id: recordId,
    }),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data: data, status: response.status };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};
