import React, { useEffect, useState } from "react";
import "../custom-css/Custom-Calendar.css";
import "../custom-css/DayWidget.css";
import { getTimeFromDate } from "../functions/date_format";
import { Link, NavLink } from "react-router-dom";

const DayWidgetDashboard = ({ day, appointments }) => {
  const selectedDay = day.getDate(); // Extract the day from the selected date
  const selectedMonth = day.getMonth(); // Extract the month from the selected date
  const selectedYear = day.getFullYear();
  const today = new Date().getDate();

  const filteredConsultations = appointments.filter((appointment) => {
    const appointmentDate = new Date(appointment.date);
    return (
      appointmentDate.getDate() === selectedDay &&
      appointmentDate.getMonth() === selectedMonth &&
      appointmentDate.getFullYear() === selectedYear
    );
  });

  return (
    <div className="day-widget-container">
      <div className="day-widget-title">
        <h3>
          Your appointments{" "}
          {day.getDate() === today ? "Today" : day.toLocaleDateString()}
        </h3>
        {filteredConsultations.length === 0 ? (
          <p>No appointments for this day.</p>
        ) : (
          <ul>
            {filteredConsultations.map((appointment, index) => (
              <WidgetConsultation appointment={appointment} />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

const WidgetConsultation = ({ appointment }) => {
  const link =
    appointment.activity_type === "Consultation"
      ? "/consultation/details/"
      : "/discovery-call/details/";

  return (
    <div class="consultation-line-card">
      <div className="label-card">
        <p>
          {appointment.activity_type == "Consultation"
            ? "Consultation"
            : "Discovery Call"}
        </p>
      </div>
      <p class="consultation-line-card__content">
        <strong>{appointment.client_name}</strong>
      </p>
      <div class="consultation-line-card__date">
        Time: <strong>{getTimeFromDate(appointment.date)}</strong>
      </div>
      <Link to={`${link}${appointment.id}`} className="user-link">
        <div class="consultation-line-card__arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            height="15"
            width="15"
          >
            <path
              fill="#fff"
              d="M13.4697 17.9697C13.1768 18.2626 13.1768 18.7374 13.4697 19.0303C13.7626 19.3232 14.2374 19.3232 14.5303 19.0303L20.3232 13.2374C21.0066 12.554 21.0066 11.446 20.3232 10.7626L14.5303 4.96967C14.2374 4.67678 13.7626 4.67678 13.4697 4.96967C13.1768 5.26256 13.1768 5.73744 13.4697 6.03033L18.6893 11.25H4C3.58579 11.25 3.25 11.5858 3.25 12C3.25 12.4142 3.58579 12.75 4 12.75H18.6893L13.4697 17.9697Z"
            ></path>
          </svg>
        </div>
      </Link>
    </div>
  );
};

export { DayWidgetDashboard, WidgetConsultation };
