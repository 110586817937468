// ProfileTable.js
import React, { useState } from "react";
import "../../custom-css/ProfileTable.css";
import "../../custom-css/App.css";
import { formatDate } from "../../functions/date_format";
import ModifyProfile from "../Modals/ModifyProfile";
import { languageFormat } from "../../functions/language";
import ModalConvertLead from "../../Leads/Modal/ModalConvertLead";
import { Link } from "react-router-dom";

const ProfileTable = ({ userDetails, isClient, onUpdateClient }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalConvertOpen, setIsModalConvertOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleModifyProfile = () => {
    // Open the modal when the "Add New Client" button is clicked
    setIsModalOpen(true);
  };

  const handleModalConvertClose = () => {
    setIsModalConvertOpen(false);
  };

  const handleModalClose = () => {
    // Close the modal
    setIsModalOpen(false);
  };

  const handleConvert = async () => {
    setIsModalConvertOpen(true);
  };

  const visitInstaProfile = (insta) => {
    window.open(`https://www.instagram.com/${insta}`, "_blank");
  };

  return (
    <div className="profile-table-container">
      <div className="title-container-expandable">
        <div className="title-table-notes-expanded">
          <h3>Profile</h3>
          {expanded ? (
            <div className="btn-title-container">
              <button
                onClick={handleModifyProfile}
                className="button-10 add-client-button"
              >
                Modify
              </button>
              {!isClient ? (
                <button
                  onClick={handleConvert}
                  className="second-btn-additional button-10"
                >
                  Convert to client
                </button>
              ) : null}
            </div>
          ) : null}
        </div>
        {expanded ? (
          <button onClick={handleExpand} className="button-13">
            <p>See less</p>
            <i class="fa-solid fa-chevron-up"></i>
          </button>
        ) : (
          <button onClick={handleExpand} className="button-13">
            <p>See more</p>
            <i class="fa-solid fa-chevron-down"></i>
          </button>
        )}
      </div>
      {expanded ? (
        <table className="profile-table">
          <tbody>
            <tr>
              <td className="cell-header profile-table-row-title">ID:</td>
              <td className="table-cell">{userDetails?.id}</td>
            </tr>
            {userDetails?.converted_lead ? (
              <tr>
                <td className="cell-header profile-table-row-title">
                  Converted Lead:
                </td>
                <td className="table-cell">
                  <Link
                    className="link-lead-converted"
                    to={`/leads/details/${userDetails?.lead_id}`}
                  >
                    {userDetails?.lead_id}
                  </Link>
                </td>
              </tr>
            ) : null}

            <tr>
              <td className="cell-header profile-table-row-title">Name:</td>
              <td className="table-cell">{userDetails?.name}</td>
            </tr>
            <tr>
              <td className="cell-header profile-table-row-title">
                Email Address:
              </td>
              <td className="table-cell">{userDetails?.email_address}</td>
            </tr>
            <tr>
              <td className="cell-header profile-table-row-title">Phone:</td>
              <td className="table-cell">{userDetails?.phone_number}</td>
            </tr>
            <tr>
              <td className="cell-header profile-table-row-title">Age:</td>
              <td className="table-cell">{userDetails?.age}</td>
            </tr>
            <tr>
              <td className="cell-header profile-table-row-title">City:</td>
              <td className="table-cell">{userDetails?.city}</td>
            </tr>
            <tr>
              <td className="cell-header profile-table-row-title">Telegram:</td>
              <td className="table-cell">{userDetails?.telegram_id}</td>
            </tr>
            <tr>
              <td className="cell-header profile-table-row-title">
                Instagram:
              </td>
              <td
                onClick={
                  userDetails.instagram_id
                    ? () => visitInstaProfile(userDetails.instagram_id)
                    : null
                }
                className="table-cell insta-cell"
              >
                {userDetails?.instagram_id}
              </td>
            </tr>
            <tr>
              <td className="cell-header profile-table-row-title">
                Created on:
              </td>
              <td className="table-cell">{formatDate(userDetails?.date)}</td>
            </tr>
            <tr>
              <td className="cell-header profile-table-row-title">Language:</td>
              <td className="table-cell">
                {languageFormat(userDetails?.language)}
              </td>
            </tr>
          </tbody>
        </table>
      ) : null}

      <ModifyProfile
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onUpdateDone={onUpdateClient}
        initialFormData={userDetails}
        type={isClient ? "client" : "lead"}
      />
      <ModalConvertLead
        isOpen={isModalConvertOpen}
        onClose={handleModalConvertClose}
        onConvertedDone={onUpdateClient}
        leadDetails={userDetails}
        lead_id={userDetails?.id}
      />
    </div>
  );
};

export default ProfileTable;
