import React, { useEffect, useState } from "react";
import TopRow from "../TopRow/TopRow";
import recordsData from "../json/records.json";
import { getAllDiscoveryCalls } from "../api/discovery_calls";
import DiscoveryCallsTable from "./DiscoveryCallsTable";
import ErrorModal from "../GeneralModal/ErrorSessionModal";



const DiscoveryCallsPage = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [discoveryData, setDiscovery] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    // Fetch client data when the component mounts
    handleLeadAdded();
  }, []);

  useEffect(() => {
    // Fetch and set users data from the imported JSON file
    setDiscovery(discoveryData);
  }, []);
  const handleAddLead = () => {
    // Open the modal when the "Add New Client" button is clicked
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    // Close the modal
    setIsModalOpen(false);
  };

  const handleSuccess = (newClientData) => {

  };
  const handleLeadAdded = async () => {
    try {
      const response = await getAllDiscoveryCalls();
      if (response.success) {
        // Assuming the fetched data is in response.data
        setDiscovery(response.data);
      } else {
        // Handle fetch failureshowErrorModal("An error occurred while fetching data.");
        setShowErrorModal(true);
        console.error("Fetching clients failed:", response.error);
      }
    } catch (error) {
      setShowErrorModal(true);
      console.error("Error during client data fetching:", error);
      // Handle error
    }
  };
  return (
    <div className="users-scrollable-container">
      <div className="users">
        <div className="navbar">
          <TopRow adminName={'Admin'} />
        </div>
        <div className="users-container">
          <div className="users-header">
            <h2>Discovery Calls
            </h2>
          </div>
          <DiscoveryCallsTable
            discoveryCalls={discoveryData}
          />
        </div>
      </div>
      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};

export default DiscoveryCallsPage;
