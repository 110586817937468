import {
  DELETE_SUPPLEMENT_CLIENT_URL,
  DELETE_SUPPLEMENT_LIST_URL,
  EDIT_SUPPLEMENT_CLIENT_URL,
  EDIT_SUPPLEMENT_URL,
  GET_ALL_SUPPLEMENTS_URL,
  GET_SUPPLEMENTS_BY_CONSULTATION_URL,
  GET_SUPPLEMENTS_PREVIOUS_CONSULTATION,
  GET_SUPPLEMENT_BY_ID,
  GET_SUPP_SPEC_BY_CONSULTATION_URL,
  INSERT_NEW_SUPPLEMENT_LIST_URL,
  INSERT_SUPPLEMENT_CLIENT_URL,
  INSERT_SUPP_SPEC_CLIENT_URL,
} from "./api_urls";

import { getAdminId, getSessionId } from "./api_urls";

// Function to perform login API call
export const getAllSupplements = async () => {
  const apiUrl = GET_ALL_SUPPLEMENTS_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const getSupplementById = async (id) => {
  const apiUrl = GET_SUPPLEMENT_BY_ID;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "supplement-id": id,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const getSupplementsByConsultation = async (consultationId) => {
  const apiUrl = GET_SUPPLEMENTS_BY_CONSULTATION_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "consultation-id": consultationId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const getSuppSpecByConsultation = async (consultationId) => {
  const apiUrl = GET_SUPP_SPEC_BY_CONSULTATION_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "consultation-id": consultationId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const insertNewSupplement = async (name, link, image) => {
  const apiUrl = INSERT_NEW_SUPPLEMENT_LIST_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
    },
    body: JSON.stringify({ name: name, link: link, image_url: image }),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const insertSupplementClient = async (
  client_id,
  consultation_id,
  dose,
  morning,
  day,
  evening,
  notes,
  supplement_id
) => {
  const apiUrl = INSERT_SUPPLEMENT_CLIENT_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
    },
    body: JSON.stringify({
      client_id: client_id,
      consultation_id: consultation_id,
      dose: dose,
      morning: morning,
      day: day,
      evening: evening,
      notes: notes,
      supplement_id: supplement_id,
    }),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const insertSuppSpec = async (
  clientId,
  consultationId,
  week,
  review_weeks
) => {
  const apiUrl = INSERT_SUPP_SPEC_CLIENT_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
    },
    body: JSON.stringify({
      client_id: clientId,
      consultation_id: consultationId,
      week: week,
      review_week: review_weeks,
    }),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const deleteSupplementClient = async (id) => {
  const apiUrl = DELETE_SUPPLEMENT_CLIENT_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "supplement-id": id,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();
      console.log("response", data);
      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const deleteSupplementList = async (id) => {
  const apiUrl = DELETE_SUPPLEMENT_LIST_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "supp-id": id,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const editSupplement = async (name, link, imageUrl, supplementId) => {
  const apiUrl = EDIT_SUPPLEMENT_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "supplement-id": supplementId,
    },
    body: JSON.stringify({
      name: name,
      link: link,
      image_url: imageUrl,
    }),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const editSupplementClient = async (
  supp_id,
  consultation_id,
  dose,
  morning,
  day,
  evening,
  notes
) => {
  const apiUrl = EDIT_SUPPLEMENT_CLIENT_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "supp-id": supp_id,
      "consultation-id": consultation_id,
    },
    body: JSON.stringify({
      dose: dose,
      morning: morning,
      day: day,
      evening: evening,
      notes_instructions: notes,
    }),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const getSupplementsPreviousConsultation = async (clientId, consultationId) => {
  const apiUrl = GET_SUPPLEMENTS_PREVIOUS_CONSULTATION;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "consultation-id": consultationId,
      "client-id": clientId
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};