import React from "react";

const PreviewComponent = ({ link }) => {
  const getFileExtension = (filename) => {
    return filename.split(".").pop().toLowerCase();
  };

  const renderPreview = () => {
    const extension = getFileExtension(link);

    if (extension === "pdf") {
      return (
        <embed src={link} type="application/pdf" width="100%" height="600px" />
      );
    } else if (
      extension === "png" ||
      extension === "jpg" ||
      extension === "jpeg"
    ) {
      return (
        <img
          src={link}
          alt="Preview"
          style={{ maxWidth: "100%", maxHeight: "600px" }}
        />
      );
    } else {
      return <p>Unsupported file format</p>;
    }
  };

  return <div>{renderPreview()}</div>;
};

export default PreviewComponent;
