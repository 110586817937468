import {
  DELETE_DISCOVERY_URL,
  GET_ALL_DISCOVERY_CALLS_URL,
  GET_DISCOVERY_CALL_BY_ID_URL,
  SET_DATE_DISCOVERY_URL,
} from "./api_urls";

import { getAdminId, getSessionId } from "./api_urls";

// Function to perform login API call
export const getAllDiscoveryCalls = async (id) => {
  const apiUrl = GET_ALL_DISCOVERY_CALLS_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const getDiscoveryById = async (leadId) => {
  const apiUrl = GET_DISCOVERY_CALL_BY_ID_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "lead-id": leadId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const setDateDiscovery = async (leadId, dateNew) => {
  const apiUrl = SET_DATE_DISCOVERY_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
    },
    body: JSON.stringify({
      new_date: dateNew,
      lead_id: leadId,
    }),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    const statusCode = response.status;

    // Parse the response data if it's a successful status code
    if (response.ok) {
      const data = await response.json();

      return { success: true, data, statusCode };
    } else {
      const error = await response.json();

      // Handle non-successful status codes here
      return { success: false, error };
    }
  } catch (error) {
    throw new Error("An error occurred while inserting the client.");
  }
};

export const deleteDiscoveryCall = async (leadId) => {
  const apiUrl = DELETE_DISCOVERY_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "lead-id": leadId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    const statusCode = response.status;

    // Parse the response data if it's a successful status code
    if (response.ok) {
      const data = await response.json();

      return { success: true, data, statusCode };
    } else {
      const error = await response.json();

      // Handle non-successful status codes here
      return { success: false, error };
    }
  } catch (error) {
    throw new Error("An error occurred while inserting the client.");
  }
};
