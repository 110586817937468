import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TopRow from "../../TopRow/TopRow";
import "../../custom-css/UserDetails.css";
import ErrorModal from "../../GeneralModal/ErrorSessionModal";
import { getRecordById } from "../../api/records";
import RecordDetailsTable from "../../Records/RecordDetailsTable";
import { getSupplementById } from "../../api/supplements";
import SupplementDetailsTable from "./SupplmentDetailsTable";
import ModalEditSupplement from "../Modal/ModalEditSupplement";

const SupplementDetailsPage = () => {
  const { id } = useParams();
  const [supplementDetails, setSupplementDetails] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    fetchSupplement();
  }, [id]);

  const handleEdit = () => {
    // Open the modal when the "Add New Client" button is clicked
    setIsEditModalOpen(true);
  };

  const handleModalCloseEdit = () => {
    // Close the modal
    setIsEditModalOpen(false);
  };

  const fetchSupplement = async () => {
    try {
      const response = await getSupplementById(id);
      if (response.success) {
        setSupplementDetails(response.data);
      } else {
        // Handle error
        console.error("Failed to fetch appointments:");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching appointments:", error);

      setShowErrorModal(true);
    }
  };

  return (
    <div className="user-details-main-container">
      <div className="navbar">
        <TopRow adminName={"Admin"} />
      </div>
      <div className="user-details-internal-container">
        <h2>
          Supplement / {id} /{" "}
          {supplementDetails === null ? null : supplementDetails.name}
        </h2>
        <div className="user-details-first-row"></div>
      </div>
      <div className="supplement-details-btn-line">
        <button onClick={handleEdit} className="button-10">
          Edit
        </button>
        <button className="button-14 btn-delete-supplement">Delete</button>
      </div>
      <SupplementDetailsTable supplementDetails={supplementDetails} />

      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
      <ModalEditSupplement
        isOpen={isEditModalOpen}
        onClose={handleModalCloseEdit}
        onEditDone={fetchSupplement}
        initialFormData={supplementDetails}
        supplementId={id}
      />
    </div>
  );
};

export default SupplementDetailsPage;
