import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../custom-css/UserTable.css";
import { languageFormat } from "../functions/language";

const UserTable = ({ userData }) => {
  const [searchQuery, setSearchQuery] = useState(""); // State variable for search query
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDirection, setSortDirection] = useState("");
  const [sortedColumn, setSortedColumn] = useState("name");
  const itemsPerPage = 15; // Number of items to display per page

  const handleSort = (column) => {
    setSortedColumn(column);
    setSortDirection(
      sortedColumn === column && sortDirection === "asc" ? "desc" : "asc"
    );
  };

  const sortedUsers = [...userData].sort((a, b) => {
    const valueA = a[sortedColumn].toLowerCase();
    const valueB = b[sortedColumn].toLowerCase();
    if (valueA < valueB) {
      return sortDirection === "asc" ? -1 : 1;
    }
    if (valueA > valueB) {
      return sortDirection === "asc" ? 1 : -1;
    }
    return 0;
  });

  // Filter the users based on the search query
  const filteredUsers = sortedUsers.filter(
    (user) =>
      user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.phone_number.includes(searchQuery.toLowerCase()) ||
      user.email_address.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  // Function to generate an array of visible page numbers
  const getVisiblePageNumbers = () => {
    const maxPageButtons = Math.ceil(userData.length / 10) + 1; // Number of page buttons to display

    if (totalPages <= maxPageButtons) {
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    } else {
      const halfButtons = Math.floor(maxPageButtons / 2);
      const firstVisiblePage = Math.max(1, currentPage - halfButtons);
      const lastVisiblePage = Math.min(totalPages, currentPage + halfButtons);

      return Array.from(
        { length: lastVisiblePage - firstVisiblePage + 1 },
        (_, index) => firstVisiblePage + index
      );
    }
  };

  // Handle changes in the search input
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when the search query changes
  };

  // Handle pagination button clicks
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <div className="search-user">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          placeholder="Search by name, phone number, email address"
          value={searchQuery}
          onChange={handleSearchInputChange}
          className="search-bar"
        />
      </div>
      <div className="table-container">
        <table className="table-users">
          <thead>
            <tr>
              <th className="table-header">ID</th>
              <th
                className="table-header name-header"
                onClick={() => handleSort("name")}
              >
                Name
                {sortedColumn === "name" && (
                  <i
                    className={`fas fa-sort-${
                      sortDirection === "asc" ? "up" : "down"
                    }`}
                  ></i>
                )}
              </th>
              <th className="table-header">Email</th>
              <th className="table-header">Phone</th>
              <th className="table-header">Language</th>
              <th className="table-header">Was Lead?</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers
              .slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
              )
              .map((user) => (
                <tr key={user.id}>
                  <td className="table-cell">{user.id}</td>
                  <td className="table-cell">
                    <Link to={`/user/details/${user.id}`} className="user-link">
                      {user.name}
                    </Link>
                  </td>
                  <td className="table-cell">{user.email_address}</td>
                  <td className="table-cell">{user.phone_number}</td>
                  <td className="table-cell">
                    {languageFormat(user.language)}
                  </td>
                  <td className="table-cell">
                    {user.converted_lead ? "Yes" : "No"}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* Pagination */}
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn-pagination"
        >
          Previous
        </button>
        {getVisiblePageNumbers().map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={
              currentPage === page
                ? "btn-pagination btn-pagination-active"
                : "btn-pagination"
            }
          >
            {page}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="btn-pagination"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default UserTable;
