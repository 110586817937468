// api/auth.js
import { GET_CLIENT_DATA_URL, GET_INTAKE_FORM_URL } from "./api_urls";
import { getAdminId, getSessionId } from "./api_urls";

// Function to perform login API call
export const getClientData = async (id) => {
  const apiUrl = GET_CLIENT_DATA_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "client-id": id,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();
      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const getIntakeForm = async (id, languageId) => {
  const apiUrl = GET_INTAKE_FORM_URL;
  const adminId = getAdminId();
  const sessionId = getSessionId();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "admin-id": adminId,
      "session-id": sessionId,
      "client-id": id,
      "language-id": languageId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();
      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};
