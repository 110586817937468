import React, { useState, useEffect } from "react";
import "../../custom-css/NotesTable.css";
import AddNoteModal from "../Modals/AddNoteModal";
import { getNotesByClientId } from "../../api/notes";
import { formatDateTime } from "../../functions/date_format";
import DeleteNoteModal from "../Modals/DeleteNoteModal";
import TitleTablesExpandable from "./TitleTablesExpandable";
import TitleTables from "../../GenericComponents/TitleTables";
import ErrorModal from "../../GeneralModal/ErrorSessionModal";

const NotesTable = ({ userId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteNoteModalOpen, setIsDeleteNoteModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [notes, setNotes] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const notesPerPage = 5;

  // Function to fetch notes from the API
  const fetchNotes = async () => {
    try {
      const response = await getNotesByClientId(userId);
      if (response.success) {
        setNotes(response.data);
        setTotalPages(Math.ceil(response.data.length / notesPerPage));
      } else {
        console.error("Fetching notes failed:", response.error);
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error("Error during notes fetching:", error);
      setShowErrorModal(true);
    }
  };

  // Fetch notes when the component mounts
  useEffect(() => {
    fetchNotes();
  }, [userId]);

  // Function to handle page changes
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the index range for the current page
  const indexOfLastNote = currentPage * notesPerPage;
  const indexOfFirstNote = indexOfLastNote - notesPerPage;
  const currentNotes = notes.slice(indexOfFirstNote, indexOfLastNote);

  // Function to handle adding a new note
  const handleAddNote = () => {
    setIsModalOpen(true);
  };

  // Function to handle closing the modal
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleDeleteNoteModalClose = () => {
    setIsDeleteNoteModalOpen(false);
  };

  const handleDeleteNote = (noteId) => {
    setNoteToDelete(noteId);
    setIsDeleteNoteModalOpen(true);
  };

  // Function to handle success after adding a new note
  const handleSuccess = () => {
    // Refetch notes after adding a new note
    fetchNotes();
  };

  return (
    <div className="notes-table">
      <TitleTables
        title={"Notes"}
        buttonText={"Add new note"}
        onClick={handleAddNote}
      />
      {notes.length === 0 ? null : (
        <React.Fragment>
          <table className="table-notes">
            <tbody>
              <tr>
                <td className="notes-table-column-title delete-header"></td>
                <td className="cell-header notes-table-column-title">Date</td>
                <td className="notes-table-column-title">Note</td>
              </tr>
              {currentNotes.map((note) => (
                <tr key={note.id}>
                  <td className="table-cell delete-cell">
                    <button
                      onClick={() => handleDeleteNote(note.id)}
                      className="button-12"
                    >
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  </td>
                  <td className="table-cell date-cell">
                    {formatDateTime(note.date)}
                  </td>
                  <td className="table-cell">{note.text}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={currentPage === index + 1 ? "active" : ""}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </React.Fragment>
      )}

      <AddNoteModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        entityId={userId}
        onNoteAdded={fetchNotes}
        type={"client"}
      />
      <DeleteNoteModal
        isOpen={isDeleteNoteModalOpen}
        onClose={handleDeleteNoteModalClose}
        noteId={noteToDelete}
        onNoteDeleted={fetchNotes}
        type={"client"}
      />
      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};

export default NotesTable;
