import React, { useState } from "react";
import "../custom-css/App.css";
import "../custom-css/Tables.css";


const TitleTables = ({ title, buttonText, onClick }) => {

    const [expanded, setExpanded] = useState(false);

    const handleExpand = () => {
        setExpanded(!expanded);
    }
    return (
        <div>
            <div className="title-table-notes">
                <h3>{title}</h3>
                <div className="btn-title-container">
                    <button onClick={onClick} className="button-10 add-client-button">
                        {buttonText}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TitleTables;
