import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../custom-css/ProfileTable.css";
import ErrorModal from "../../GeneralModal/ErrorSessionModal";
import { getClientLogin } from "../../api/get_clients";
import { CLIENT_AREA_LINK, domainClientArea } from "../../api/api_urls";

const LoginTable = () => {
  const { id } = useParams();
  //   const [isModalOpen, setIsModalOpen] = useState(false);
  const [loginDetails, setLoginDetails] = useState([]);
  const [expand, setExpand] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [copied, setCopied] = useState(false);

  const fetchData = async () => {
    try {
      const response = await getClientLogin(id); // Fetch client data
      if (response.success) {
        // Assuming the fetched data is in response.data as an array of objects
        setLoginDetails(response.data);
      } else {
        // Handle fetch failure
        console.error("Fetching client data failed:", response.error);
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error("Error during client data fetching:", error);
      setShowErrorModal(true);
      // Handle error
    }
  };

  useEffect(() => {
    fetchData(); // Call the fetchData function to fetch client data
  }, [id]);

  const handleExpand = () => {
    setExpand(!expand);
  };
  const handleCopy = () => {
    const linkToCopy = domainClientArea; // Replace this with your actual link
    navigator.clipboard.writeText(linkToCopy).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Hide tooltip after 2 seconds
    });
  };

  return (
    <div className="profile-table-container">
      <div className="title-container-expandable">
        <div className="title-table-notes-expandable">
          <h3>ClientArea Login</h3>
        </div>
        <button onClick={handleExpand} className="button-13">
          <p>{expand ? "See less" : "See more"}</p>
          <i className={`fa-solid fa-chevron-${expand ? "up" : "down"}`}></i>
        </button>
      </div>
      {expand ? (
        <table className="profile-table">
          <tbody>
            <tr>
              <td className="cell-header profile-table-row-title">Link</td>
              <td className="table-cell">
                <p className="client-area-link" onClick={handleCopy}>
                  Client Area Link
                  {copied && (
                    <span className="tooltip-client-area-link">
                      Link copied
                    </span>
                  )}
                </p>
              </td>
            </tr>
            <tr>
              <td className="cell-header profile-table-row-title">Username:</td>
              <td className="table-cell">{loginDetails?.username}</td>
            </tr>
            <tr>
              <td className="cell-header profile-table-row-title">Password:</td>
              <td className="table-cell">{loginDetails?.password}</td>
            </tr>
          </tbody>
        </table>
      ) : null}

      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};

export default LoginTable;
